import React from "react";

import { Redirect } from "react-router-dom";
import { AuthService } from "../services";
import { AuthUser } from "../services/AuthService";

interface AuthGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
  const [auth, setAuth] = React.useState<AuthUser | null>(null);
  const [isAuthResolved, setIsAuthResolved] = React.useState(false);

  React.useEffect(() => {
    AuthService.getCurrentUser().then((currentUser: AuthUser | null) => {
      setAuth(currentUser);
      setIsAuthResolved(true);
    });
  }, []);

  if (isAuthResolved) {
    if (auth) {
      return children;
    }

    return <Redirect to="/auth/sign-in" />;
  }

  return null;
}

export default AuthGuard;
