import { ChangeEvent, VFC } from "react";
import styled from "styled-components";

import {
  TextField as MuiTextField, TextFieldProps,
} from "@material-ui/core";

import { spacing, SpacingProps } from "@material-ui/system";

const TextField = styled(MuiTextField)(spacing);

interface FFTextFieldProps {
  maxLength?: number;
}

export const FFTextField: VFC<TextFieldProps & SpacingProps & FFTextFieldProps> = (props) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const value = event.target.value;

    if (props.maxLength && value?.length > props.maxLength) {
      event.target.value = value.slice(0, -1);
    } else {
      props.onChange && props.onChange(event);
    }
  }

  return (
    <TextField
      fullWidth={true}
      {...props}
      onChange={handleChange}
    />
  )
}

