import { BaseList, BasePagination } from "../types/api";
import { api } from "./BackendService";

export interface Campaign {
    createdAt: string;
    default: boolean;
    description: string;
    enabled: boolean;
    endAt: string;
    fee: number;
    id: string;
    link: string;
    splitPercent: number;
    startAt: string;
    title: string;
    updatedAt: string;
}

export interface CampaignFormData {
    title: string;
    description: string;
    splitPercent: number;
    startAt: Date;
    endAt: Date;
    link?: string;
}

export const getById = (id: string) => api.get<Campaign>(`/fee-modalities/${id}`);
export const list = (params: BasePagination) => api.get<BaseList<Campaign>>("/fee-modalities", { params });
export const create = (data: CampaignFormData) => api.post('/fee-modalities', data);
export const update = (id: string, data: CampaignFormData) => api.put(`/fee-modalities/${id}`, data);
export const deleteById = (id: string) => api.delete(`/fee-modalities/${id}`);
