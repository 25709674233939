import { ProtectedPage } from "../../components";
import CampaignForm from "../../components/CampaignForm";

export const CampaignsFormPage = () => {
    return (
        <ProtectedPage
            title="Criar Campanha"
            breadcrumbs={[
                { name: "Campanhas", to: "/campaigns" },
                { name: "Criar Campanha", to: "/campaigns/new" },
            ]}
        >
            <CampaignForm />
        </ProtectedPage>
    );
};
