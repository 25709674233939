import { BasePagination } from "../types/api";
import { api } from "./BackendService";
import { CombateFraudeReports } from "./CombateFraudeService";
import { CnpjStatus, Company } from "./CompaniesService";
import { CrefStatus, Trainer } from "./TrainersService";

type Role = 'company' | 'regular' | 'trainer';
type Status = 'active' | 'inactive' | 'blocked';
type SubAccountStatus = 'accepted' | 'rejected' | 'pending';
type CafStatus = 'APPROVED' | 'REPROVED' | 'PENDING';

export interface ProfileListParams extends BasePagination {
  role?: Role;
  status?: Status;
  name?: string;
  username?: string;
  crefStatus?: CrefStatus;
  cref?: string;
  cafStatus?: CafStatus;
  cnpj?: string;
  cnpjStatus?: CnpjStatus;
  cpf?: string;
  subAccountStatus?: SubAccountStatus;
}

export interface ProfileListResponse {
  combateFraudeReports: CombateFraudeReports[];
  company: Company;
  createAt: string;
  id: string;
  image: { location: string };
  role: Role;
  status: Status;
  subAccountStatus: SubAccountStatus;
  trainer: Trainer;
  updatedAt: string;
  username: string;
  users: Array<{ name: string }>;
}

export const create = (data: any) => api.post("/profiles", data);

export const edit = (id: number, data: any) => api.put(`/profiles/${id}`, data);

export const getById = (id: number) => api.get(`/profiles/${id}`);

export const list = (params: ProfileListParams) => api.get<ProfileListResponse>("/profiles", { params });

export const fetchBanks = () => api.get(`/banks`);

export const pendingTrainers = () => api.get(`/cref/verify-status-pending`);
