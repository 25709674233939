import { api } from "./BackendService";

export type CrefStatus = 'pending' | 'approved' | 'rejected';

export interface Trainer {
    createdAt: string
    cref: string
    crefVerificationStatus: CrefStatus;
    id: string;
}

export const edit = (id: string, data: any) => api.put(`/trainers/${id}`, data);
