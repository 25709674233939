import { CategoriesForm, ProtectedPage } from "../../components";
import { CategoryService } from "../../services";

export const CategoriesRegisterFormPage = () => {
  const handleSubmit = async (form: any) => {
    await CategoryService.create(form);
  };

  return (
    <ProtectedPage
      title="Cadastrar Categoria"
      breadcrumbs={[
        { name: 'Categorias', to: '/categories' },
        { name: 'Cadastrar Categoria', to: '/categories/new' }
      ]}
    >

      <CategoriesForm
        buttonName="Salvar"
        formTitle="Cadastrar Categoria"
        handleSubmit={handleSubmit}
        resetFields={true}
        successMessage="Categoria Cadastrada com Sucesso!"
      />
    </ProtectedPage>

  );
};
