import { useEffect, useState } from "react";
import {
  ProfilesService,
  CombateFraudeService,
  CompaniesService,
  WithdrawsService,
} from "../services";
import "./Notification.css";
import { getCrefCount, setCrefCount } from "../services/CrefCountService";
import { getCurrentUser } from "../services/AuthService";

const Notification = (props: { target: string }) => {
  if (props.target === "Saques") return <WithdrawNotification />;
  else return <ProfileNotification />;
};

const WithdrawNotification = () => {
  const [count, setCount] = useState(0);
  const service = WithdrawsService;

  useEffect(() => {
    getCurrentUser().then(response => {
      if (!response) return;

      service.getMissingInvoicesCount().then((res) => {
        setCount(res.data)
      }).catch((err) => console.log(err));
    })
  }, []);

  if (count === 0) return <div></div>

  return (
    <div className="badge">
      {count}
    </div>
  )
};

const ProfileNotification = () => {
  const [trainerCounter, setTrainerCounter] = useState(getCrefCount());
  const [caf, setCaf] = useState<number>(0);
  const [cnpj, setCnpj] = useState<number>(0);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    (async () => {
      const user = await getCurrentUser();
      if (!user) return;
      ProfilesService.pendingTrainers().then((response) => {
        if (response.data.count !== trainerCounter) {
          setTrainerCounter(response.data.count);
          setCrefCount(response.data.count);
        }
      });

      CombateFraudeService.list()
        .then((response) => {
          setCaf(response.data.count);
        })
        .catch((error) => {
          console.log(error);
        });

      CompaniesService.list()
        .then((response) => {
          setCnpj(response.data.count);
        })
        .catch((error) => {
          console.log(error);
        });
    })();
  }, []);


  const displayCount = isNaN(trainerCounter + caf + cnpj) ? 0 : trainerCounter + caf + cnpj
  if (displayCount === 0) return <div></div>

  return (
    <div className="badge">
      {displayCount}
    </div>
  );
};

export default Notification;
