import { spacing, SpacingProps } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
    Box,
    Button as MuiButton,
    Card as MuiCard,
    CardContent,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { FFTextField } from "./FFTextField";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { create, update } from "../services/NotificationsService";
import { useAlert } from "../hooks/useAlert";
import { useHistory, useParams } from "react-router-dom";
import { NotificationsService } from "../services";
import { isPast } from "date-fns";

interface ButtonPropstype extends SpacingProps {
    component?: string;
}

const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)<ButtonPropstype>(spacing);

interface NotificationsFormData {
    id: string;
    targetGroup: 'company' | 'trainer' | 'regular';
    title: string;
    subtitle: string;
    status: 'sended' | 'scheduled';
    sendOption: 'sendNow' | 'schedule' | 'sent';
    scheduledAt: Date | null;

}

interface NotificationsFormProps {
    data?: NotificationsFormData;
}

const NotificationsForm: React.FC<NotificationsFormProps> = () => {
    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState<boolean>(false);
    const isEditing = !!id;
    const history = useHistory();

    const cleanFormData: Partial<NotificationsFormData> = {
        title: '',
        subtitle: '',
        sendOption: 'sendNow',
        targetGroup: 'company',
    }

    const [formData, setFormData] = useState<Partial<NotificationsFormData>>(cleanFormData);
    const [errors, setErrors] = useState<Partial<Record<keyof NotificationsFormData, string>>>({});

    const { alertOptions, showAlert } = useAlert();

    useEffect(() => {
        if (id) {
            setLoading(true);
            NotificationsService.getById(id)
                .then((response) => {
                    const { data } = response;
                    setFormData({
                        title: data.title,
                        subtitle: data.description,
                        targetGroup: data.targetGroup,
                        scheduledAt: new Date(data.scheduledAt),
                        sendOption: isPast(new Date(data.scheduledAt)) ? 'sent' : 'schedule',
                    });
                })
                .catch((error) => {
                    console.error(error);
                    showAlert('Ocorreu um erro ao buscar a notificação', 'error');
                })
                .finally(() => setLoading(false));
        }
    }, [id]);

    const cleanRequired = (inputName: keyof NotificationsFormData): void => {
        errors[inputName] = '';
        setErrors({ ...errors });
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const { name, value } = event.target;
        cleanRequired(name as keyof NotificationsFormData);
        setFormData({ ...formData, [name]: value });
    }

    const checkRequiredInputs = (): boolean => {
        const requiredInputs: Array<keyof NotificationsFormData> = ['title', 'subtitle'];
        let hasError = false;

        requiredInputs.forEach((inputName) => {
            if (!formData[inputName]) {
                hasError = true;
                errors[inputName] = `Campo ${inputName} é obrigatório`;
                setErrors({ ...errors });
            }
        });

        return hasError;
    }

    const handleSubmit = async (): Promise<void> => {
        if (checkRequiredInputs()) {
            return;
        }

        const body: any = {
            title: formData.title,
            subtitle: formData.subtitle,
            scheduledAt: formData.sendOption === 'sendNow' ? new Date() : formData.scheduledAt,
            targetGroup: formData.targetGroup,
            url: '/main/home',
        }

        setLoading(true);
        (isEditing ? update(id, body) : create(body))
            .then(() => {
                showAlert('Notificação salva com sucesso!', 'success');
                history.push('/notifications');
            })
            .catch((error) => {
                console.error(error);
                showAlert('Ocorreu um erro ao criar a notificação', 'error')
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <Card mb={6}>
            <CardContent>
                <Typography variant="h4" gutterBottom>
                    {isEditing ? 'Editar' : 'Criar'} Notificação
                </Typography>

                {alertOptions?.show && (
                    <Alert severity={alertOptions.type}>
                        {alertOptions?.message}
                    </Alert>
                )}

                {loading ? (
                    <Box display="flex" justifyContent="center" my={6}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Paper>
                        <form>
                            <Grid item md={6} style={{ marginBottom: "16px" }}>
                                <FFTextField
                                    fullWidth
                                    label="Título"
                                    mt={2}
                                    name="title"
                                    onChange={handleInputChange}
                                    placeholder="Digite o título da notificação"
                                    value={formData.title}
                                    variant="outlined"
                                    maxLength={40}
                                    required
                                    error={!!errors?.title}
                                    helperText={errors?.title}
                                />
                                <span> {`${formData.title?.length || 0} / 40`} </span>
                            </Grid>
                            <Grid item md={6} style={{ marginBottom: "16px" }}>
                                <FFTextField
                                    fullWidth
                                    label="Mensagem"
                                    mt={2}
                                    name="subtitle"
                                    onChange={handleInputChange}
                                    placeholder="Digite a mensagem da notificação"
                                    value={formData.subtitle}
                                    variant="outlined"
                                    maxLength={150}
                                    multiline
                                    required
                                    error={!!errors?.subtitle}
                                    helperText={errors?.subtitle}
                                />
                                <span> {`${formData.subtitle?.length || 0} / 150`} </span>
                            </Grid>
                            <Grid item style={{ marginBottom: "16px" }}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Público alvo</FormLabel>
                                    <RadioGroup
                                        aria-label="Envio"
                                        name="targetGroup"
                                        value={formData.targetGroup}
                                        onChange={handleInputChange}
                                    >
                                        <FormControlLabel
                                            value="company"
                                            control={<Radio color="primary" />}
                                            label="Profissional PJ"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="trainer"
                                            control={<Radio color="primary" />}
                                            label="Profissional PF"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="regular"
                                            control={<Radio color="primary" />}
                                            label="Aluno"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="notregistered"
                                            control={<Radio color="primary" />}
                                            label="Não Cadastrado"
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid item style={{ marginBottom: "16px" }}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Tipo de envio</FormLabel>
                                    <RadioGroup
                                        aria-label="Envio"
                                        name="sendOption"
                                        value={formData.sendOption}
                                        onChange={handleInputChange}
                                    >
                                        {formData.sendOption === 'sent' && (
                                            <FormControlLabel
                                                value="sent"
                                                control={<Radio color="primary" />}
                                                label="Enviado"
                                                labelPlacement="end"
                                            />
                                        )}
                                        <FormControlLabel
                                            value="sendNow"
                                            control={<Radio color="primary" />}
                                            label="Enviar agora"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="schedule"
                                            control={<Radio color="primary" />}
                                            label="Agendar envio"
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            {formData.sendOption !== 'sendNow' && (
                                <Grid item md={6} style={{ marginBottom: "16px" }}>
                                    <KeyboardDateTimePicker
                                        autoOk
                                        fullWidth
                                        variant="inline"
                                        inputVariant="outlined"
                                        label="Data de envio"
                                        format="dd/MM/yyyy HH:mm"
                                        ampm={false}
                                        value={formData.scheduledAt}
                                        onChange={(date: Date | null) => setFormData({ ...formData, scheduledAt: date })}
                                    />
                                </Grid>
                            )}

                            <Button
                                onClick={handleSubmit}
                                variant="contained"
                                color="primary"
                                mt={4}
                            >
                                {formData.sendOption === 'sent' ? (
                                    <span> Salvar </span>
                                ) : (
                                    <span> Salvar e {formData.sendOption === 'sendNow' ? 'enviar' : 'agendar'} </span>
                                )}
                            </Button>
                        </form>
                    </Paper>
                )}
            </CardContent>
        </Card>
    );
};
export default NotificationsForm;
