import { ProtectedPage } from "../../components";

import GroupsForm from "../../components/GroupsForm";
import { GroupService } from "../../services";

export const GroupsRegisterFormPage = () => {
  const handleSubmit = async (form: any) => {
    await GroupService.create(form);
  };

  return (
    <ProtectedPage
      title="Cadastrar Grupo"
      breadcrumbs={[
        { name: "Grupos", to: "/groups" },
        { name: "Cadastrar Grupo", to: "/groups/new" },
      ]}
    >
      <GroupsForm
        buttonName="Salvar"
        formTitle="Criar Grupo"
        handleSubmit={handleSubmit}
        resetFields={true}
        successMessage="Grupo Cadastrado com Sucesso!"
      />
    </ProtectedPage>
  );
};
