import { format } from "date-fns";
import { ProtectedPage } from "../../components";
import { spacing } from "@material-ui/system";
import { useParams } from "react-router-dom";
import React from "react";
import styled from "styled-components";

import {
    Card as MuiCard,
    CardContent,
    Grid,
    GridSize,
    Paper as MuiPaper,
    Typography,
} from "@material-ui/core";
import { Campaign, getById } from "../../services/CampaignsService";
import { useAlert } from "../../hooks/useAlert";
import { CustomAlert } from "../../components/CustomAlert";

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

interface FormatedValues extends Omit<Campaign, 'splitPercent'> {
    splitPercent: string;
}

function DetailPage() {
    const { id } = useParams<{ id: string }>();
    const [data, setData] = React.useState<FormatedValues>();

    const { alertOptions, showAlert } = useAlert();

    const formatValues = (dataRow: Campaign): FormatedValues => {
        return {
            ...dataRow,
            startAt: format(new Date(dataRow.startAt), 'dd/MM/yyyy'),
            endAt: format(new Date(dataRow.endAt), 'dd/MM/yyyy'),
            splitPercent: `${dataRow.splitPercent}%`,
        };
    }

    React.useEffect(() => {
        getById(id)
            .then((response) => setData(formatValues(response.data)))
            .catch((error) => {
                console.error(error);
                showAlert('Ocorreu um erro ao buscar as campanhas', 'error');
            })
    }, [id]);

    const gridValues: { label: string; key: keyof FormatedValues; md?: GridSize }[] = [
        { label: 'ID', key: 'id' },
        { label: 'Título', key: 'title' },
        { label: 'Link', key: 'link' },
        { label: 'Data de início', key: 'startAt' },
        { label: 'Data de fim', key: 'endAt' },
        { label: 'Porcentagem', key: 'splitPercent' },
        { label: 'Descrição', key: 'description', md: 12 },
    ];

    return (
        <div>
            <CustomAlert alertOptions={alertOptions} />

            <Paper>
                <Card mb={6}>
                    <CardContent>
                        <Typography variant="h4" gutterBottom style={{ marginBottom: "16px" }}>
                            Visualizar Campanha
                        </Typography>

                        <Grid container spacing={8}>
                            {gridValues.map((gridItem) => (
                                <Grid key={gridItem.key} item md={gridItem.md || 4}>
                                    <Typography variant="h6" style={{ marginBottom: '8px' }}>
                                        {gridItem.label}
                                    </Typography>
                                    <Typography variant="body1">
                                        {data?.[gridItem.key]}
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    </CardContent>
                </Card>
            </Paper>
        </div>
    )
}

export const CampaignDetailPage = () => {
    return (
        <ProtectedPage
            title="Visualizar Campanha"
            breadcrumbs={[
                { name: 'Campanhas', to: '/campaigns' },
                { name: 'Visualizar Campanha', to: '/campaigns/:id/campaign-detail' }
            ]}
        >
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <DetailPage />
                </Grid>
            </Grid>
        </ProtectedPage>
    );
};
