

import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";


export const Container = styled.div`
  z-index: 9;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.2);  
`;

interface ISize {
  width?: string;

}

export const Content = styled.div<ISize>`
  border-radius: 5px;
  margin-left: 16px;
  position: relative;
  background-color: ${(props) => props.theme.palette.background.default};
  margin: auto;
  padding: 0;
  width: ${({ width }) => width};
  -webkit-animation-name:animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  @media (max-width: 1023px) {
    width: 90%;
  }
`;

export const Title = styled.h2`
  font-size: 16px;
  color: ${(props) => props.theme.palette.background.default};
  letter-spacing: 1px;
  font-weight: 800;
`;

export const Header = styled.div`
  border-radius: 5px 5px 0 0;
  padding: 2px 16px;
  background-color:  #ed3998;
  color: ${(props) => props.theme.palette.background.default};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Close = styled(CloseIcon).attrs(props => ({
  fontSize: "large"
}))`
  color: ${(props) => props.theme.palette.background.default};
  float: right;
  font-size: 16px
  font-weight: bold;
  &:hover {
    color: #00AEEF;
    text-decoration: none;
    cursor: pointer;
  }

  &:focus {
    color: #00AEEF;
    text-decoration: none;
    cursor: pointer;
  }
`;
