import { AdminService } from "../../services";
import { format, parseISO } from "date-fns";
import { ProtectedPage } from "../../components";
import { pt } from "date-fns/locale";
import { spacing } from "@material-ui/system";
import { useParams } from "react-router-dom";
import React from "react";
import styled from "styled-components";

import {
  Card as MuiCard,
  CardContent,
  Grid,
  Paper as MuiPaper,
  Typography,
} from "@material-ui/core";

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

function DetailPage() {
  const [data, setData] = React.useState<any>([]);
  const { id } = useParams<{ id: any }>();

  React.useEffect(() => {
    (async () => {
      const resList = await AdminService.getById(id);
      setData(resList.data);
    })();
  }, []);

  return (
    <div>
      <Paper>
        <Card mb={6}>
          <CardContent>
            <Typography variant="h4" gutterBottom={true} style={{ marginBottom: "16px" }}>
              Informações detalhadas
            </Typography>

            <Grid container={true} spacing={8}>
              <Grid item={true} md={12}>
                <Grid container={true} spacing={8} style={{ marginBottom: '16px' }}>
                  <Grid item={true} md={4}>
                    <Typography variant="h6" style={{ marginBottom: '8px' }}>
                      Nome
                    </Typography>
                    <Typography variant="body1">
                      {data?.name}
                    </Typography>
                  </Grid>
                  <Grid item={true} md={4}>
                    <Typography variant="h6" style={{ marginBottom: '8px' }}>
                      Sobrenome
                    </Typography>
                    <Typography variant="body1">
                      {data?.lastname}
                    </Typography>
                  </Grid>
                  <Grid item={true} md={4}>
                    <Typography variant="h6" style={{ marginBottom: '8px' }}>
                      Email
                    </Typography>
                    <Typography variant="body1">
                      {data?.email}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container={true} spacing={8} style={{ marginBottom: '16px' }}>
                  <Grid item={true} md={4}>
                    <Typography variant="h6" style={{ marginBottom: '8px' }}>
                      ID
                    </Typography>
                    <Typography variant="body1">
                      {data?.id}
                    </Typography>
                  </Grid>
                  <Grid item={true} md={4}>
                    <Typography variant="h6" style={{ marginBottom: '8px' }}>
                      Status
                    </Typography>
                    <Typography variant="body1">
                      {
                        data?.enabled ? "Ativo" : "Inativo"
                      }
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container={true} spacing={8} style={{ marginBottom: '16px' }}>
                  <Grid item={true} md={4}>
                    <Typography variant="h6" style={{ marginBottom: '8px' }}>
                      Data de Criação
                    </Typography>
                    <Typography variant="body1">
                      {data?.createdAt && format(parseISO(data.createdAt), "Pp", {
                        locale: pt,
                      })}
                    </Typography>
                  </Grid>
                  <Grid item={true} md={4}>
                    <Typography variant="h6" style={{ marginBottom: '8px' }}>
                      Data de Atualização
                    </Typography>
                    <Typography variant="body1">
                      {data?.updatedAt && format(parseISO(data.updatedAt), "Pp", {
                        locale: pt,
                      })}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
    </div>
  )
}

export const AdminDetailPage = () => {
  return (
    <ProtectedPage
      title="Administrador detalhado"
      breadcrumbs={[
        { name: 'Administradores', to: '/admin-users' },
        { name: 'Administrador detalhado', to: '/admin-users/:id/admin-detail' }
      ]}
    >
      <Grid container={true} spacing={6}>
        <Grid item={true} xs={12}>
          <DetailPage />
        </Grid>
      </Grid>
    </ProtectedPage>
  );
};
