import { useState, useEffect, VFC } from "react";
import styled from "styled-components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
} from "@material-ui/core";

import {
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";

import {
  Edit as EditIcon,
} from "@material-ui/icons";

import { BuyerBalanceService } from "../../services";
import { FFTextField, ProtectedPage } from "../../components";
import { spacing } from "@material-ui/system";
import { useAlert } from "../../hooks/useAlert";
import { CustomAlert } from "../../components/CustomAlert";

const Paper = styled(MuiPaper)(spacing);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      margin: theme.spacing(1),
    },
    dialog: {
      maxWidth: "50px",
    },
  })
);

interface SearchOptions {
  page: number;
  id: string;
  name: string;
  document: string;
  phoneNumber: string;
}

interface ModalProps {
  onClose: () => void;
  onError: (error: Error) => void;
  onSubmit: () => void;
  isOpen: boolean;
  userId: string;
}

const EditBalanceModal: VFC<ModalProps> = ({ onSubmit, onError, onClose, isOpen, userId }) => {
  const [open, setOpen] = useState<boolean>(isOpen);
  const [amount, setAmount] = useState<number>();

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = (): void => {
    setOpen(false);
    setAmount(undefined);
    onClose();
  };

  const handleSubmit = async (): Promise<void> => {
    if (!amount) return;
    try {
      await BuyerBalanceService.edit(userId, amount);
      onSubmit();
    } catch (error) {
      onError(error);
    }
    handleClose();
  };

  return (
    <Dialog id="EditBalanceModal" open={open} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">{`Digite o novo saldo`}</DialogTitle>
      <TextField
        autoFocus
        margin="normal"
        id="amount"
        type="number"
        variant="standard"
        value={amount}
        onChange={(event) => setAmount(Number(event.target.value))}
      />
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus={true}>
          Voltar
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function EnhancedTable() {
  const [data, setData] = useState<any>();
  const [showBalanceEditModal, setShowBalanceEditModal] = useState<boolean>(
    false
  );
  const [userIdToEdit, setUserIdToEdit] = useState<string>("");
  const classes = useStyles();
  const [searchOptions, setSearchOptions] = useState<SearchOptions>({
    page: 0,
    id: "",
    name: "",
    document: "",
    phoneNumber: "",
  });

  const { page, id, name, document, phoneNumber } = searchOptions;
  const { alertOptions, showAlert } = useAlert();

  const fetchBalances = async () => {
    const resList = await BuyerBalanceService.list(
      page,
      6,
      document,
      name,
      id,
      phoneNumber
    );
    setData(resList.data);
  };

  useEffect(() => {
    fetchBalances();
  }, [id, name, document, phoneNumber, page]);

  const onFilterChange = (key: string, value: string) => {
    setSearchOptions({
      ...searchOptions,
      page: 0,
      [key]: value,
    });
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setSearchOptions({ ...searchOptions, page: newPage });
  };

  const showModal = (id: string) => {
    setUserIdToEdit(id);
    setShowBalanceEditModal(true);
  };
  
  const closeModal = () => {
    setShowBalanceEditModal(false);
    fetchBalances();
  }

  return (
    <div>
      <CustomAlert alertOptions={alertOptions} />
      <EditBalanceModal
        isOpen={showBalanceEditModal}
        onClose={closeModal}
        onError={(error) => {
          showAlert('Não foi possível editar o saldo', 'error')
        }}
        onSubmit={() => {
          showAlert('Saldo atualizado', 'success');
        }}
        userId={userIdToEdit}
      />
      <Grid container={true} spacing={8} justify="flex-end">
        <Grid item={true}>
          <FFTextField
            className={classes.textField}
            label="Pesquisar por ID de usuário"
            onChange={(event) => onFilterChange("id", event.target.value)}
            placeholder="Digite o ID"
            value={id}
          />
        </Grid>
        <Grid item={true}>
          <FFTextField
            className={classes.textField}
            label="Pesquisar por nome"
            onChange={(event) => onFilterChange("name", event.target.value)}
            placeholder="Digite o nome"
            value={name}
          />
        </Grid>
        <Grid item={true}>
          <FFTextField
            className={classes.textField}
            label="Pesquisar por CPF/CNPJ"
            onChange={(event) => onFilterChange("document", event.target.value)}
            placeholder="Digite o CPJ/CNPJ"
            value={document}
          />
        </Grid>
        <Grid item={true}>
          <FFTextField
            className={classes.textField}
            label="Pesquisar por telefone"
            onChange={(event) =>
              onFilterChange("phoneNumber", event.target.value)
            }
            placeholder="Digite o telefone"
            value={phoneNumber}
          />
        </Grid>
      </Grid>

      <Grid container={true} spacing={8}>
        <Grid item={true} xs={12}>
          <Paper>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableCell>Nome</TableCell>
                  <TableCell>CPF/CNPJ</TableCell>
                  <TableCell>E-mail</TableCell>
                  <TableCell>Saldo</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableHead>
                <TableBody>
                  {data?.rows?.map((row: any, index: number) => {
                    return (
                      <TableRow
                        hover={true}
                        key={`${row.id}-${index}`}
                        tabIndex={-1}
                      >
                        <TableCell component="th" scope="row">
                          <Typography>
                            <strong>{`${row?.name} ${row?.lastname}`}</strong>
                            <br />
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography>{row?.document}</Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row?.email}
                        </TableCell>
                        <TableCell>
                          {row?.profile?.buyerBalance.amount}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="edit"
                            title="Editar saldo"
                            onClick={() => showModal(row?.id)}
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[6]}
              component="div"
              count={data?.count}
              rowsPerPage={6}
              page={page}
              onChangePage={handleChangePage}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export const BuyerBalancesIndexPage = () => {
  return (
    <ProtectedPage
      title="Carteiras"
      breadcrumbs={[
        {
          name: "Carteiras",
          to: "/buyer-balances",
        },
      ]}
    >
      <Grid container={true} spacing={6}>
        <Grid item={true} xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </ProtectedPage>
  );
};
