import { Color } from "@material-ui/lab";
import { useEffect, useState } from "react";

export interface AlertOptions {
    show: boolean;
    message: string;
    type: Color;
}

export const useAlert = () => {
    const [alertOptions, setAlertOptions] = useState<AlertOptions>();

    const resetAlert = (): void => {
        setAlertOptions({ show: false, message: '', type: 'success' })
    }

    useEffect(() => {
        let timeoutId: number;

        if (alertOptions?.show) {
            timeoutId = window.setTimeout(() => resetAlert(), 3000);
        }

        return () => clearTimeout(timeoutId);
    }, [alertOptions]);

    const showAlert = (message: string, type: Color): void => {
        setAlertOptions({
            show: true,
            message,
            type,
        });
    }

    return { showAlert, alertOptions };
}