import { spacing } from "@material-ui/system";
import { format, parseISO } from "date-fns";
import { ProfilesService } from "../../services";
import { ProtectedPage } from "../../components";
import { pt } from "date-fns/locale";
import { useParams } from "react-router-dom";
import React from "react";
import styled from "styled-components";

import {
  Avatar,
  Card as MuiCard,
  CardContent,
  Grid,
  Paper as MuiPaper,
  Typography,
} from "@material-ui/core";

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

const BigAvatar = styled(Avatar)`
  height: 120px;
  margin: 0 auto;
  width: 120px;
`;

function DetailPage() {
  const [data, setData] = React.useState<any>([]);
  const { id } = useParams<{ id: any }>();

  React.useEffect(() => {
    (async () => {
      const resList = await ProfilesService.getById(id);
      setData(resList.data);
    })();
  }, []);

  const bankData = () => {
    return (
      <div>
        <Typography variant="h4" gutterBottom={true} style={{ marginBottom: "16px" }}>
          Dados Bancários
        </Typography>

        <Grid container={true} spacing={8} style={{ marginBottom: '30px' }}>
          <Grid item={true} md={12}>
            <Grid container={true} spacing={8} style={{ marginBottom: '16px' }}>
              <Grid item={true} md={4}>
                <Typography variant="h6" style={{ marginBottom: '8px' }}>
                  Banco
                </Typography>
                <Typography variant="body1">
                  {data?.bankAccount?.bank?.code} - {data?.bankAccount?.bank?.name}
                </Typography>
              </Grid>
              <Grid item={true} md={4}>
                <Typography variant="h6" style={{ marginBottom: '8px' }}>
                  Tipo de Conta Bancária
                </Typography>
                <Typography variant="body1">
                  {data?.bankAccount?.accountType === 'checking account' ? 'Conta corrente' : ''}
                  {data?.bankAccount?.accountType === 'savings account' ? 'Conta poupança' : ''}
                  {data?.bankAccount?.accountType === 'payment account' ? 'Conta pagamento' : ''}
                </Typography>
              </Grid>
              <Grid item={true} md={4}>
                <Typography variant="h6" style={{ marginBottom: '8px' }}>
                  Agência
                </Typography>
                <Typography variant="body1">
                  {data?.bankAccount?.branchNumber} - {data?.bankAccount?.branchDigit}
                </Typography>
              </Grid>
            </Grid>
            <Grid container={true} spacing={8} style={{ marginBottom: '16px' }}>
              <Grid item={true} md={4}>
                <Typography variant="h6" style={{ marginBottom: '8px' }}>
                  Conta
                </Typography>
                <Typography variant="body1">
                  {data?.bankAccount?.accountNumber} - {data?.bankAccount?.accountDigit}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

  return (
    <div>
      <Paper>
        <Card mb={6}>
          <CardContent>
            <Typography variant="h4" gutterBottom={true} style={{ marginBottom: "16px" }}>
              Informações detalhadas
            </Typography>

            <Grid container={true} spacing={8} style={{ marginBottom: '30px' }}>
              <Grid item={true} md={12}>
                <Grid container={true} spacing={8} style={{ marginBottom: "16px" }}>
                  <Grid item={true} md={4}>
                    <Typography variant="h6" style={{ marginBottom: '8px' }}>
                      Nome de Usuário
                    </Typography>
                    <Typography variant="body1">
                      {data?.username}
                    </Typography>
                  </Grid>
                  <Grid item={true} md={4}>
                    <Typography variant="h6" style={{ marginBottom: '8px' }}>
                      Tipo de Conta
                    </Typography>
                    <Typography variant="body1">
                      {data?.role === "trainer" ? "Personal Trainer / Treinador" : ""}
                      {data?.role === "company" ? "Academia" : ""}
                      {data?.role === "regular" ? "Usuário regular" : ""}
                    </Typography>
                  </Grid>
                  <Grid item={true} md={4}>
                    <BigAvatar alt={data?.username} src={data?.image?.location || data?.image} />
                  </Grid>
                </Grid>
                <Grid container={true} spacing={8} style={{ marginBottom: "24px" }}>
                  <Grid item={true} md={4}>
                    <Typography variant="h6" style={{ marginBottom: '8px' }}>
                      Descrição
                    </Typography>
                    <Typography variant="body1">
                      {data?.description}
                    </Typography>
                  </Grid>
                  <Grid item={true} md={4}>
                    <Typography variant="h6" style={{ marginBottom: '8px' }}>
                      Status
                    </Typography>
                    <Typography variant="body1">
                      {data?.status === "active" ? "Ativo" : ""}
                      {data?.status === "inactive" ? "Inativo" : ""}
                      {data?.status === "blocked" ? "Bloqueado" : ""}
                    </Typography>
                  </Grid>
                  <Grid item={true} md={4}>
                    <Typography variant="h6" style={{ marginBottom: '8px' }}>
                      ID
                    </Typography>
                    <Typography variant="body1">
                      {data?.id}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container={true} spacing={8} style={{ marginBottom: "24px" }}>
                  <Grid item={true} md={4}>
                    <Typography variant="h6" style={{ marginBottom: '8px' }}>
                      Data de Criação
                    </Typography>
                    <Typography variant="body1">
                      {data?.createdAt && format(parseISO(data.createdAt), "Pp", {
                        locale: pt,
                      })}
                    </Typography>
                  </Grid>
                  <Grid item={true} md={4}>
                    <Typography variant="h6" style={{ marginBottom: '8px' }}>
                      Data de Atualização
                    </Typography>
                    <Typography variant="body1">
                      {data?.updatedAt && format(parseISO(data.updatedAt), "Pp", {
                        locale: pt,
                      })}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {data?.trainer && (bankData())}

            {data?.company && (bankData())}

            <Typography variant="h4" gutterBottom={true} style={{ marginBottom: "16px" }}>
              Usuários
            </Typography>

            {
              data?.users?.map((row: any) => {
                return (
                  <Grid container={true} spacing={8} style={{ marginBottom: '30px' }}>
                    <Grid item={true} md={12}>
                      <Grid container={true} spacing={8} style={{ marginBottom: "16px" }}>
                        <Grid item={true} md={4}>
                          <Typography variant="h6" style={{ marginBottom: '8px' }}>
                            Nome
                          </Typography>
                          <Typography variant="body1">
                            {row.name}
                          </Typography>
                        </Grid>
                        <Grid item={true} md={4}>
                          <Typography variant="h6" style={{ marginBottom: '8px' }}>
                            Sobrenome
                          </Typography>
                          <Typography variant="body1">
                            {row.lastName}
                          </Typography>
                        </Grid>
                        <Grid item={true} md={4}>
                          <Typography variant="h6" style={{ marginBottom: '8px' }}>
                            Gênero
                          </Typography>
                          <Typography variant="body1">
                            {row.gender === "male" ? "Masculino" : ""}
                            {row.gender === "female" ? "Feminino" : ""}
                            {row.gender === "other" ? "Outro" : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container={true} spacing={8} style={{ marginBottom: "16px" }}>
                        <Grid item={true} md={4}>
                          <Typography variant="h6" style={{ marginBottom: '8px' }}>
                            Data de Nascimento
                          </Typography>
                          <Typography variant="body1">
                            {row?.birthdate && format(parseISO(row.birthdate), "Pp", {
                              locale: pt,
                            })}
                          </Typography>
                        </Grid>
                        <Grid item={true} md={4}>
                          <Typography variant="h6" style={{ marginBottom: '8px' }}>
                            CPF
                          </Typography>
                          <Typography variant="body1">
                            {row.document}
                          </Typography>
                        </Grid>
                        <Grid item={true} md={4}>
                          <Typography variant="h6" style={{ marginBottom: '8px' }}>
                            Email
                          </Typography>
                          <Typography variant="body1">
                            {row.email}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container={true} spacing={8} style={{ marginBottom: "16px" }}>
                        <Grid item={true} md={4}>
                          <Typography variant="h6" style={{ marginBottom: '8px' }}>
                            Telefone
                          </Typography>
                          <Typography variant="body1">
                            {row.phone.countryCode} {row.phone.areaCode} {row.phone.number}
                          </Typography>
                        </Grid>
                      {row.address ?
                        <Grid item={true} md={4}>
                          <Typography variant="h6" style={{ marginBottom: '8px' }}>
                            Endereço
                          </Typography>
                          <Typography>
                            {row.address.street}, {row.address.number}, {row.address.complement} - {row.address.zipcode}, {row.address.district}, {row.address.city}, {row.address.state}
                          </Typography>
                        </Grid>
                        : null }
                      </Grid>
                      <Grid container={true} spacing={8} style={{ marginBottom: "16px" }}>
                        <Grid item={true} md={4}>
                          <Typography variant="h6" style={{ marginBottom: '8px' }}>
                            ID do Usuário
                          </Typography>
                          <Typography variant="body1">
                            {row.id}
                          </Typography>
                        </Grid>
                        <Grid item={true} md={4}>
                          <Typography variant="h6" style={{ marginBottom: '8px' }}>
                            Status do Usuário
                          </Typography>
                          <Typography variant="body1">
                            {row.status === "active" ? "Ativo" : ""}
                            {row.status === "inactive" ? "Inativo" : ""}
                            {row.status === "blocked" ? "Bloqueado" : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })
            }
          </CardContent>
        </Card>
      </Paper>
    </div>
  );
}

export const ProfilesDetailPage = () => {

  return (
    <ProtectedPage
      title="Perfil detalhado"
      breadcrumbs={[
        { name: "Perfis", to: "/profiles" },
        { name: "Perfil detalhado", to: "/profiles/:id/detail" },
      ]}
    >
      <Grid container={true} spacing={6}>
        <Grid item={true} xs={12}>
          <DetailPage />
        </Grid>
      </Grid>
    </ProtectedPage>
  );
};
