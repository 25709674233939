import { api } from "./BackendService";

export interface CombateFraudeReports {
    createdAt: string;
    data: string;
    executionId: string;
    id: string;
    profileId: string;
    pushToken: string;
    reportId: string;
    updatedAt: string;
}

export const list = () => api.get("/combate-fraude/caf-verify-status-pending");