import { Chip, FFTextField, ProtectedPage } from "../../components";
import FFModal from "../../components/Modal";
import { format, parseISO, subMinutes, isBefore } from "date-fns";
import { NavLink } from "react-router-dom";
import {
  ProfilesService,
  TrainersService,
  CompaniesService,
  CombateFraudeService,
} from "../../services";
import { pt } from "date-fns/locale";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  Avatar as MuiAvatar,
  Button,
  createStyles,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper as MuiPaper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";

import {
  Edit as EditIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  CheckCircle as CheckCircleIcon,
} from "@material-ui/icons";

import { Alert as MuiAlert } from "@material-ui/lab";
import { getCrefCount } from "../../services/CrefCountService";
import { ProfileListParams } from "../../services/ProfilesService";
import { debounce } from "../../utils/time";

const Paper = styled(MuiPaper)(spacing);
const Alert = styled(MuiAlert)(spacing);

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
  margin-right: 16px;
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  padding-left: 16px;
`;
const BtnContainer = styled.div`
  width: 50%;
  display: flex;
  align-self: center;
  margin: 16px;
`;
const ChildContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatusContainer = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
`;
const ChildContainerAux = styled.div`
  display: flex;
  flex-direction: row;
`;
const CounterSpan = styled.span``;

const CrefCafModalSpan = styled.span`
  padding-top: 20px;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(4),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    textField: {
      margin: theme.spacing(1),
      minWidth: 140,
    },
  })
);

interface IRowMetadata {
  rowId: number;
  defaultState: string;
  dbRowId: any;
}

interface ITrainerMetadata {
  createdAt: string;
  cref: string;
  crefVerificationStatus: string;
  id: string;
}

interface ICompany {
  addressId: string;
  cnpjStatus: any;
  createdAt: string;
  document: string;
  id: string;
  name: string;
  phoneId: string;
}

const filterByOptions = {
  noFilters: 'Sem filtros',
  role: 'Tipo de conta',
  crefStatus: 'Status CREF',
  cnpjStatus: 'Status CNPJ',
  cafStatus: 'Status CAF',
  subAccountStatus: 'Status Iugu',
};

const filterOptions = {
  role: [
    { label: 'Empresa', value: 'company' },
    { label: 'Aluno', value: 'regular' },
    { label: 'Treinador', value: 'trainer' },
  ],
  crefStatus: [
    { label: 'Pendente', value: 'pending' },
    { label: 'Aprovado', value: 'approved' },
    { label: 'Reprovado', value: 'rejected' },
  ],
  cnpjStatus: [
    { label: 'Pendente', value: 'pending' },
    { label: 'Aprovado', value: 'approved' },
    { label: 'Reprovado', value: 'rejected' },
  ],
  cafStatus: [
    { label: 'Pendente', value: 'PENDING' },
    { label: 'Aprovado', value: 'APPROVED' },
    { label: 'Reprovado', value: 'REPROVED' },
  ],
  subAccountStatus: [
    { label: 'Pendente', value: 'pending' },
    { label: 'Aprovado', value: 'accepted' },
    { label: 'Reprovado', value: 'rejected' },
  ],
};

const translatedRoles = {
  company: 'Empresa',
  regular: 'Aluno',
  trainer: 'Treinador',
};

export const ProfilesIndexPage = () => {
  const [data, setData] = useState<any>();
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState<boolean>(false);
  const [crefModal, setCrefModal] = useState<boolean>(false);
  const [cnpjModal, setCnpjModal] = useState<boolean>(false);
  const [rowMetadata, setRowMetadata] = useState<IRowMetadata>({
    rowId: 0,
    defaultState: "",
    dbRowId: "",
  });
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>();
  const [caf, setCaf] = useState<number>(0);
  const [cnpj, setCnpj] = useState<number>(0);
  const [filterBy, setFilterBy] = useState<keyof typeof filterByOptions>('noFilters');
  const [searchParams, setSearchParams] = useState<ProfileListParams>({ page: 0 });

  const classes = useStyles();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setSearchParams({ ...searchParams, page: newPage });
  };

  const cleanSelectedFilters = () => {
    (Object.keys(filterOptions) as Array<keyof typeof filterOptions>).forEach((item) => searchParams[item] = undefined);
  }

  useEffect(() => {
    ProfilesService.list(searchParams)
      .then((response) => setData(response.data))
      .catch((error) => {
        console.error(error);
        setError('Erro ao buscar usuários');
      })
  }, [searchParams]);

  useEffect(() => {
    cleanSelectedFilters();
    setSearchParams({ ...searchParams, page: 0 });
  }, [filterBy]);

  const formatCAFChips = (data?: { status: string, createdAt: string }) => {
    if (!data) return;
    switch (data.status) {
      case 'APPROVED':
        return <Chip color="#4aff4a" message="Aprovado" />;
      case 'REPROVED':
        return <Chip color="#ffc000" message="Reprovado" />
      default:
        if (isBefore(new Date(data.createdAt), subMinutes(new Date(), 3))) return <Chip color="#ff7070" message="Travado" />;
        return <Chip color="#c9c9c9" message="Aguardando" />
    }
  }

  useEffect(() => {
    CombateFraudeService.list()
      .then((response) => {
        setCaf(response.data.count);
      })
      .catch((error) => {
        setError('Erro ao buscar as informações do CAF');
        console.error(error);
      });
  }, []);

  useEffect(() => {
    CompaniesService.list()
      .then((response: any) => {
        setCnpj(response.data.count);
      })
      .catch((error: any) => {
        setError('Erro ao buscar os CNPJs pendentes')
        console.error(error);
      });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const dimissNotification = () => {
    if (error) {
      setTimeout(() => {
        setError('');
      }, 10000);
    }
    if (success) {
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    }
  };

  const changeStatus = (index: number, row: any) => {
    setRowMetadata({
      rowId: index,
      defaultState: row.status,
      dbRowId: row.id,
    });
  };

  const handleStatus = (value: string) => {
    const currentRows = data.rows;
    currentRows[rowMetadata.rowId].status = value;
    setData({ ...data, rows: currentRows });
  };
  const handleModalClose = () => {
    const defaultRows = data.rows;
    defaultRows[rowMetadata.rowId].status = rowMetadata.defaultState;
    setData({ ...data, rows: defaultRows });
    setModal(false);
  };

  const saveChanges = async () => {
    let getRes;
    let putRes;
    let userCompleteData;
    try {
      getRes = await ProfilesService.getById(rowMetadata.dbRowId);
      userCompleteData = getRes.data;
      userCompleteData.status = data.rows[rowMetadata.rowId].status;
    } catch (error) {
      console.error(error);
      setError('Erro ao atualizar dados');
    }
    try {
      putRes = await ProfilesService.edit(
        rowMetadata.dbRowId,
        userCompleteData
      );
      setModal(false);
    } catch (error) {
      console.error(error);
      setError('Erro ao atualizar dados');
    }
    if (getRes?.status === 200 && putRes?.status === 200) {
      setSuccess(true);
    }
    dimissNotification();
  };

  const updateCraf = async (
    accepted: boolean,
    trainerMetaData: ITrainerMetadata
  ) => {
    let updatedTrainer;
    if (accepted) {
      updatedTrainer = {
        ...trainerMetaData,
        crefVerificationStatus: "approved",
      };
    } else {
      updatedTrainer = {
        ...trainerMetaData,
        crefVerificationStatus: "rejected",
      };
    }
    try {
      const resUpdate = await TrainersService.edit(
        trainerMetaData.id,
        updatedTrainer
      );

      if (resUpdate.status === 200) {
        setSuccess(true);
        const dataUpdated = { ...data };
        if (accepted) {
          dataUpdated.rows[rowMetadata.rowId].trainer.crefVerificationStatus =
            "approved";
        } else {
          dataUpdated.rows[rowMetadata.rowId].trainer.crefVerificationStatus =
            "rejected";
        }
        setData(dataUpdated);
      }
    } catch (error) {
      setError('Erro ao atualizar dados');
      console.error(error);
    }
    setCrefModal(false);
    dimissNotification();
  };

  const updateCnpj = async (accepted: boolean, companyMetaData: ICompany) => {
    let updatedCnpj;

    if (accepted) {
      updatedCnpj = {
        ...companyMetaData,
        cnpjStatus: "approved",
      };
    } else {
      updatedCnpj = {
        ...companyMetaData,
        cnpjStatus: "rejected",
      };
    }
    try {
      const resUpdate = await CompaniesService.edit(
        companyMetaData.id,
        updatedCnpj
      );
      if (resUpdate.status === 200) {
        setSuccess(true);
        const dataUpdated = { ...data };
        if (accepted) {
          dataUpdated.rows[rowMetadata.rowId].company.cnpjStatus = "approved";
        } else {
          dataUpdated.rows[rowMetadata.rowId].company.cnpjStatus = "rejected";
        }
        setData(dataUpdated);
      }
    } catch (error) {
      setError('Erro ao atualizar dados');
      console.error(error);
    }
    setCnpjModal(false);
    dimissNotification();
  };

  const handleTextFieldChange = (field: 'name' | 'username', value: string): void => {
    debounce(() => {
      setSearchParams({
        ...searchParams,
        [field]: value,
        page: 0,
      });
    }, 500);
  }

  const handleFilterChange = (value: string): void => {
    cleanSelectedFilters();
    setSearchParams({ ...searchParams, [filterBy]: value, page: 0 });
  }

  return (
    <ProtectedPage
      title="Perfis"
      breadcrumbs={[
        {
          name: "Perfis",
          to: "/profiles",
        },
      ]}
    >
      {error && (
        <>
          <Alert mt={2} mb={1} severity="error">
            {error}
          </Alert>
        </>
      )}
      {success && (
        <>
          <Alert severity="success">
            {"Informações atualizadas com sucesso!"}
          </Alert>
        </>
      )}

      <ChildContainer>
        <ChildContainerAux>
          <CounterSpan>
            CREF aguardando:
            <CounterSpan style={{ fontWeight: 600 }}>
              {getCrefCount()}
            </CounterSpan>
          </CounterSpan>
        </ChildContainerAux>
        <ChildContainerAux>
          <CounterSpan>
            CAF travado:
            <CounterSpan style={{ fontWeight: 600 }}> {caf}</CounterSpan>
          </CounterSpan>
        </ChildContainerAux>
        <ChildContainerAux>
          <CounterSpan>
            CNPJ aguardando:
            <CounterSpan style={{ fontWeight: 600 }}> {cnpj}</CounterSpan>
          </CounterSpan>
        </ChildContainerAux>
      </ChildContainer>
      <Grid container={true} spacing={8} justify="flex-end">
        <Grid item={true}>
          <Button className={classes.button} onClick={handleOpen}>
            Filtrar por:
          </Button>

          <FormControl className={classes.formControl}>
            <InputLabel>Selecione...</InputLabel>
            <Select
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={filterBy}
              onChange={(event) => setFilterBy(event.target.value as keyof typeof filterByOptions)}
            >
              {Object.entries(filterByOptions).map(([filter, translatedFilter]) => {
                return <MenuItem key={filter} value={filter}> {translatedFilter} </MenuItem>
              })}
            </Select>
          </FormControl>
        </Grid>

        {filterBy !== 'noFilters' && (
          <Grid item={true}>
            <Button className={classes.button} onClick={handleOpen}>
              Status:
            </Button>

            <FormControl className={classes.formControl}>
              <InputLabel>Selecione...</InputLabel>
              <Select
                value={searchParams[filterBy] || null}
                onChange={(event) => handleFilterChange(event.target.value as string)}
              >
                {filterOptions[filterBy].map((filterOption) => {
                  return <MenuItem key={filterOption.value} value={filterOption.value}>
                    {filterOption.label}
                  </MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>
        )}

        <Grid item={true}>
          <FFTextField
            className={classes.textField}
            label="Pesquisar por Nome"
            placeholder="Digite o nome"
            onChange={(event) => handleTextFieldChange('name', event.target.value)}
          />
        </Grid>
        <Grid item={true}>
          <FFTextField
            className={classes.textField}
            label="Pesquisar por Usuário"
            placeholder="Digite o usuário"
            onChange={(event) => handleTextFieldChange('username', event.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container={true} spacing={6}>
        <Grid item={true} xs={12}>
          <Paper>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Nome / Usuário</TableCell>
                    <TableCell>Tipo de Conta</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>CREF</TableCell>
                    <TableCell>CNPJ</TableCell>
                    <TableCell>Status CREF</TableCell>
                    <TableCell>Status CNPJ</TableCell>
                    <TableCell>Status CAF</TableCell>
                    <TableCell>Status Iugu</TableCell>

                    <TableCell>Criação / Atualização</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.rows?.map((row: any, index: number) => {
                    return (
                      <TableRow
                        hover={true}
                        key={`${row.id}-${index}`}
                        tabIndex={1}
                      >
                        {modal && (
                          <FFModal
                            handleClose={handleModalClose}
                            title={"Alterar Status"}
                            width="30%"
                          >
                            <Content>
                              <ChildContainer>
                                <RadioGroup
                                  aria-label="status"
                                  style={{
                                    flexDirection: "row",
                                  }}
                                  name="status"
                                  value={
                                    data.rows[rowMetadata.rowId].status || ""
                                  }
                                  onChange={(event) =>
                                    handleStatus(event.target.value)
                                  }
                                >
                                  <FormControlLabel
                                    value="active"
                                    control={<Radio color="primary" />}
                                    label="Ativo"
                                    labelPlacement="end"
                                  />
                                  <FormControlLabel
                                    value="inactive"
                                    control={<Radio color="primary" />}
                                    label="Inativo"
                                    labelPlacement="end"
                                  />
                                  <FormControlLabel
                                    value="blocked"
                                    control={<Radio color="primary" />}
                                    label="Bloqueado"
                                    labelPlacement="end"
                                  />
                                </RadioGroup>
                                <BtnContainer>
                                  <Button
                                    type="submit"
                                    fullWidth={true}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      saveChanges();
                                    }}
                                  >
                                    Atualizar
                                  </Button>
                                </BtnContainer>
                              </ChildContainer>
                            </Content>
                          </FFModal>
                        )}
                        {crefModal && (
                          <FFModal
                            handleClose={() => {
                              setCrefModal(false);
                            }}
                            title={"Alterar Status CREF"}
                            width="30%"
                          >
                            <Content style={{ paddingLeft: "0px" }}>
                              <ChildContainer>
                                <ChildContainerAux
                                  style={{ alignSelf: "center" }}
                                >
                                  <CrefCafModalSpan>
                                    {data.rows[rowMetadata.rowId].trainer.cref}
                                  </CrefCafModalSpan>
                                </ChildContainerAux>
                                <BtnContainer>
                                  <Button
                                    style={{ margin: "5px" }}
                                    type="submit"
                                    fullWidth={true}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      updateCraf(
                                        true,
                                        data.rows[rowMetadata.rowId].trainer
                                      );
                                    }}
                                  >
                                    Aceitar
                                  </Button>
                                  <Button
                                    style={{ margin: "5px" }}
                                    type="submit"
                                    fullWidth={true}
                                    variant="contained"
                                    color="default"
                                    onClick={() => {
                                      updateCraf(
                                        false,
                                        data.rows[rowMetadata.rowId].trainer
                                      );
                                    }}
                                  >
                                    Recusar
                                  </Button>
                                </BtnContainer>
                              </ChildContainer>
                            </Content>
                          </FFModal>
                        )}

                        {cnpjModal && (
                          <FFModal
                            handleClose={() => {
                              setCnpjModal(false);
                            }}
                            title={"Alterar Status CNPJ"}
                            width="30%"
                          >
                            <Content style={{ paddingLeft: "0px" }}>
                              <ChildContainer>
                                <ChildContainerAux
                                  style={{ alignSelf: "center" }}
                                >
                                  <CrefCafModalSpan>
                                    {
                                      data.rows[rowMetadata.rowId].company
                                        .document
                                    }
                                  </CrefCafModalSpan>
                                </ChildContainerAux>
                                <BtnContainer>
                                  <Button
                                    style={{ margin: "5px" }}
                                    type="submit"
                                    fullWidth={true}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      updateCnpj(
                                        true,
                                        data.rows[rowMetadata.rowId].company
                                      );
                                    }}
                                  >
                                    Aceitar
                                  </Button>
                                  <Button
                                    style={{ margin: "5px" }}
                                    type="submit"
                                    fullWidth={true}
                                    variant="contained"
                                    color="default"
                                    onClick={() => {
                                      updateCnpj(
                                        false,
                                        data.rows[rowMetadata.rowId].company
                                      );
                                    }}
                                  >
                                    Recusar
                                  </Button>
                                </BtnContainer>
                              </ChildContainer>
                            </Content>
                          </FFModal>
                        )}

                        <TableCell component="th" scope="row">
                          <Customer>
                            <Avatar src={row?.["image.location"]} />
                            <Typography>
                              <strong>
                                {row?.users?.map((name: any) => {
                                  return name.name;
                                })}
                              </strong>
                              <br />@{row?.username}
                            </Typography>
                          </Customer>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography>{translatedRoles[row.role as keyof typeof translatedRoles]}</Typography>
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            changeStatus(index, row);
                            setModal(true);
                          }}
                          component="th"
                          scope="row"
                        >
                          {row.status === "active" && (
                            <Chip color="#4aff4a" message="Ativo" />
                          )}
                          {row.status === "inactive" && (
                            <Chip color="#c9c9c9" message="Inativo" />
                          )}
                          {row.status === "blocked" && (
                            <Chip color="#ff7070" message="Bloqueado" />
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row?.trainer?.cref ? row.trainer.cref : "-"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row?.company?.document ? row.company.document : "-"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row?.trainer?.crefVerificationStatus ===
                            "approved" && (
                              <Chip color="#4aff4a" message="Aprovado" />
                            )}
                          {row?.trainer?.crefVerificationStatus ===
                            "rejected" && (
                              <Chip color="#ff7070" message="Recusado" />
                            )}
                          {row?.trainer?.crefVerificationStatus ===
                            "pending" && (
                              <StatusContainer
                                onClick={() => {
                                  changeStatus(index, row);
                                  setCrefModal(true);
                                }}
                              >
                                <Chip color="#c9c9c9" message="Aguardando" />
                                <EditIcon />
                              </StatusContainer>
                            )}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row?.company?.cnpjStatus === "approved" && (
                            <Chip color="#4aff4a" message="Aprovado" />
                          )}
                          {row?.company?.cnpjStatus === "rejected" && (
                            <Chip color="#ff7070" message="Recusado" />
                          )}
                          {row?.company?.cnpjStatus === null && (
                            <StatusContainer
                              onClick={() => {
                                changeStatus(index, row);
                                setCnpjModal(true);
                              }}
                            >
                              <Chip color="#c9c9c9" message="Aguardando" />
                              <EditIcon />
                            </StatusContainer>
                          )}
                          {row?.company?.cnpjStatus === "pending" && (
                            <StatusContainer
                              onClick={() => {
                                changeStatus(index, row);
                                setCnpjModal(true);
                              }}
                            >
                              <Chip color="#c9c9c9" message="Aguardando" />
                              <EditIcon />
                            </StatusContainer>
                          )}
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {
                            row.role !== 'regular' &&
                            formatCAFChips(typeof row?.combateFraudeReports[0]?.data === "object" ?
                              ({ ...row?.combateFraudeReports[0]?.data, createdAt: row?.combateFraudeReports[0]?.createdAt }) :
                              (row?.combateFraudeReports[0]?.data ?
                                { ...JSON.parse(row?.combateFraudeReports[0]?.data), createdAt: row?.combateFraudeReports[0]?.createdAt }
                                : null
                              )
                            )
                          }
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row?.subAccountStatus === "accepted" && (
                            <Chip color="#4aff4a" message="Aprovado" />
                          )}
                          {row?.subAccountStatus === "rejected" && (
                            <StatusContainer>
                              <IconButton
                                component={NavLink}
                                to={`profiles/${row.id}/edit`}
                                title="Validar conta Iugu novamente"
                              >
                                <Chip color="#ff7070" message="Recusado" />
                              </IconButton>
                            </StatusContainer>
                          )}
                          {row?.subAccountStatus === "pending" && (
                            <Chip color="#c9c9c9" message="Aguardando" />
                          )}
                          {row?.subAccountStatus === null && (
                            <StatusContainer>
                              <IconButton
                                component={NavLink}
                                to={`profiles/${row.id}/edit`}
                                title="Validar conta Iugu"
                              >
                                <Chip color="#c9c9c9" message="Verificar" />
                              </IconButton>
                            </StatusContainer>
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {format(parseISO(row?.createdAt), "Pp", {
                              locale: pt,
                            })}
                            <br />
                            {format(parseISO(row?.updatedAt), "Pp", {
                              locale: pt,
                            })}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          {row?.trainer?.crefVerificationStatus ===
                            "pending" && (
                              <IconButton
                                aria-label="edit-cref"
                                component={NavLink}
                                to={`/profiles/${row.id}/edit-cref`}
                                title="Editar status do CREF"
                              >
                                <CheckCircleIcon />
                              </IconButton>
                            )}
                          <IconButton
                            aria-label="edit"
                            component={NavLink}
                            to={`profiles/${row.id}/edit`}
                            title="Editar usuário"
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            aria-label="details"
                            component={NavLink}
                            to={`/profiles/${row.id}/detail`}
                            title="Visualizar usuário"
                          >
                            <RemoveRedEyeIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[6]}
              component="div"
              count={data?.count || 0}
              rowsPerPage={6}
              page={searchParams.page}
              onChangePage={handleChangePage}
            />
          </Paper>
        </Grid>
      </Grid>
    </ProtectedPage>
  );
};
