import React, { ReactNode } from "react";
import * as Styled from "./styles";

interface IModal {
  handleClose(value: boolean): void;
  children?: ReactNode;
  title: string;
  width?: string;
}

const FFModal: React.FC<IModal> = ({
  handleClose,
  children,
  title,
  width = "60%",
}) => {
  return (
    <Styled.Container>
      <Styled.Content width={width}>
        <Styled.Header>
          <Styled.Title>{title}</Styled.Title>
          <Styled.Close
            onClick={() => {
              handleClose(false);
            }}
          />
        </Styled.Header>
        {children}
      </Styled.Content>
    </Styled.Container>
  );
};

export default FFModal;
