import React from "react";
import { useParams } from "react-router-dom";

import { ProtectedPage } from "../../components";

import GroupsForm from "../../components/GroupsForm";
import { GroupService } from "../../services";

export const GroupsEditFormPage = () => {
  const [data, setData] = React.useState([]);
  const { id } = useParams<{ id: any }>();

  React.useEffect(() => {
    (async () => {
      const resList = await GroupService.getById(id);
      setData(resList.data);
    })();
  }, []);

  const handleSubmit = async (form: any) => {
    await GroupService.edit(id, form);
  };

  return (
    <ProtectedPage
      title="Editar Grupo"
      breadcrumbs={[
        { name: "Grupos", to: "/groups" },
        { name: "Editar Grupo", to: "/groups/:id/edit" },
      ]}
    >
      <GroupsForm
        data={data}
        buttonName="Atualizar"
        formTitle="Editar Grupo"
        handleSubmit={handleSubmit}
        resetFields={false}
        successMessage="Grupo atualizado com sucesso"
      />
    </ProtectedPage>
  );
};
