import { CategoryService, GroupService } from "../../services";
import { format, parseISO } from "date-fns";
import { ProtectedPage } from "../../components";
import { pt } from "date-fns/locale";
import { spacing } from "@material-ui/system";
import { useParams } from "react-router-dom";
import React from "react";
import styled from "styled-components";

import {
  Card as MuiCard,
  CardContent,
  Grid,
  Paper as MuiPaper,
  Typography,
} from "@material-ui/core";

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

function DetailPage() {
  const [data, setData] = React.useState<any>([]);
  const [groups, setGroups] = React.useState<any>();
  const [image, setImage] = React.useState("");
  const [page, setPage] = React.useState(0);
  const { id } = useParams<{ id: any }>();

  React.useEffect(() => {
    setImage(data?.image ? data.image.location : "");
  })

  React.useEffect(() => {
    (async () => {
      const resList = await CategoryService.getById(id);
      setData(resList.data);
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      const resGroups = await GroupService.list(page, 6);
      setGroups(resGroups.data)
    })();
  }, [page])

  const matchGroups = (id: any, array: any): any => {
    const group = array?.rows?.find((row: any) => {
      return row.id === id
    })
    return group?.name
  }

  return (
    <div>
      <Paper>
        <Card mb={6}>
          <CardContent>
            <Typography variant="h4" gutterBottom={true} style={{ marginBottom: "16px" }}>
              Informações detalhadas
            </Typography>

            <Grid container={true} spacing={8}>
              <Grid item={true} md={3}>
                <div>
                  <img src={image} style={{ width: '250px', borderRadius: '12px' }} />
                </div>
              </Grid>
              <Grid item={true} md={9}>
                <Grid container={true} spacing={8} style={{ marginBottom: '16px' }}>
                  <Grid item={true} md={4}>
                    <Typography variant="h6" style={{ marginBottom: '8px' }}>
                      Nome
                    </Typography>
                    <Typography variant="body1">
                      {data?.name}
                    </Typography>
                  </Grid>
                  <Grid item={true} md={4}>
                    <Typography variant="h6" style={{ marginBottom: '8px' }}>
                      Grupo
                    </Typography>
                    <Typography variant="body1">
                      {
                        matchGroups(data?.groupId, groups)
                      }
                    </Typography>
                  </Grid>
                  <Grid item={true} md={4}>
                    <Typography variant="h6" style={{ marginBottom: '8px' }}>
                      Status
                    </Typography>
                    <Typography variant="body1">
                      {
                        data?.enabled ? "Ativo" : "Inativo"
                      }
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container={true} spacing={8} style={{ marginBottom: '16px' }}>
                  <Grid item={true} md={4}>
                    <Typography variant="h6" style={{ marginBottom: '8px' }}>
                      ID
                    </Typography>
                    <Typography variant="body1">
                      {data?.id}
                    </Typography>
                  </Grid>
                  <Grid item={true} md={4}>
                    <Typography variant="h6" style={{ marginBottom: '8px' }}>
                      Ordem
                    </Typography>
                    <Typography variant="body1">
                      {data?.order}
                    </Typography>
                  </Grid>
                  <Grid item={true} md={4}>
                    <Typography variant="h6" style={{ marginBottom: '8px' }}>
                      Cor
                    </Typography>
                    <Typography variant="body1">
                      {data?.color}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container={true} spacing={8} style={{ marginBottom: '16px' }}>
                  <Grid item={true} md={4}>
                    <Typography variant="h6" style={{ marginBottom: '8px' }}>
                      Data de Criação
                    </Typography>
                    <Typography variant="body1">
                      {data?.createdAt && format(parseISO(data.createdAt), "Pp", {
                        locale: pt,
                      })}
                    </Typography>
                  </Grid>
                  <Grid item={true} md={4}>
                    <Typography variant="h6" style={{ marginBottom: '8px' }}>
                      Data de Atualização
                    </Typography>
                    <Typography variant="body1">
                      {data?.updatedAt && format(parseISO(data.updatedAt), "Pp", {
                        locale: pt,
                      })}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
    </div>
  )
}

export const CategoriesDetailPage = () => {
  return (
    <ProtectedPage
      title="Categoria detalhada"
      breadcrumbs={[
        { name: 'Categorias', to: '/categories' },
        { name: 'Categoria detalhada', to: '/banners/:id/categories-detail' }
      ]}
    >
      <Grid container={true} spacing={6}>
        <Grid item={true} xs={12}>
          <DetailPage />
        </Grid>
      </Grid>
    </ProtectedPage>
  );
};
