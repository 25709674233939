import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

export interface ChipProps {
  message?: string;
  color?: "#ff7070" | "#4aff4a" | "#c9c9c9" | "light" | "#ffc000";
}

export const Chip: React.FC<ChipProps> = (props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      div: {
        backgroundColor: props.color,
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        placeContent: "center",
        height: "32px",
        verticalAlign: "middle",
        width: "84px",
        cursor: "pointer",
      },
      typography: {
        textAlign: "center",
      },
    })
  );

  const classes = useStyles();

  return (
    <div className={classes.div}>
      <Typography className={classes.typography}>{props.message}</Typography>
    </div>
  );
};
