import { useParams } from "react-router-dom";

import React from "react";

import { ProtectedPage } from "../../components";

import { ProfilesService, iuguAccountValidationService } from "../../services";
import ProfileEditForm from "../../components/ProfileEditForm";
import { Box, Button, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import FFModal from "../../components/Modal";
import styled from "styled-components";

export const ProfilesEditFormPage = () => {
  const [data, setData] = React.useState<any>([]);
  const [status, setStatus] = React.useState({ sent: false });
  const [isSubmitting, setSubmitting] = React.useState(false);
  const { id } = useParams<{ id: any }>();
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [iuguValidationStatus, setIuguValidationStatus] = React.useState<boolean>(false);
  const [showIuguModal, setShowIuguModal] = React.useState<boolean>(false);
  const [iuguResponseText, setIuguResponseText] = React.useState<string>();

  const Content = styled.div`
    padding-left: 16px;
  `;
  const BtnContainer = styled.div`
    width: 50%;
    display: flex;
    align-self: center;
    margin: 16px;
  `;
  const ChildContainer = styled.div`
    display: flex;
    flex-direction: column;
  `;
  const ChildContainerAux = styled.div`
    display: flex;
    flex-direction: row;
  `;
  const IuguModalSpan = styled.span`
    padding-top: 20px;
  `;

  React.useEffect(() => {
    (async () => {
      const resList = await ProfilesService.getById(id);
      if (resList.data.subAccountStatus === 'accepted' || resList.data.subAccountStatus === 'pending') {
        setIuguValidationStatus(true);
      }
      setData(resList.data);
    })();
  }, []);

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      await ProfilesService.edit(id, data);
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (err: any) {
      if (err?.response?.status === 400) {
        setErrors(err?.response?.data);

      }
      setStatus({ sent: false });
      setSubmitting(false);
    }
  };

  const iuguValidation = async () => {
    try {
      setSubmitting(true);
      const iuguResponse = await iuguAccountValidationService.post({ id });
      setIuguResponseText(iuguResponse.data);
    } catch (err: any) {
      setIuguResponseText(err?.response?.data?.error);
    } finally {
      setSubmitting(false);
      setShowIuguModal(true);
    }
  };

  return (
    <ProtectedPage
      title="Editar Usuário"
      breadcrumbs={[
        { name: "Perfis", to: "/profiles" },
        { name: "Editar Perfil", to: "/profiles/:id/edit" },
      ]}
    >
      {status && status.sent && (
        <Alert style={{ marginBottom: 16 }} severity="success">
          Alteração Realizada!
        </Alert>
      )}

      {isSubmitting ? (
        <Box display="flex" justifyContent="center" my={6}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <ProfileEditForm data={data} onDataChange={setData} errors={errors} successMessage="Perfil Atualizado com Sucesso" />
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Salvar Alterações
          </Button>
          <Button variant="contained" color="primary" onClick={iuguValidation} disabled={iuguValidationStatus} style={{ marginLeft: 10 }}>
            Validar conta Iugu
          </Button>
        </>
      )}

      {showIuguModal && (
        <FFModal
          handleClose={() => {
            setShowIuguModal(false);
          }}
          title={"Validação da conta Iugu"}
          width="50%"
        >
          <Content style={{ paddingLeft: "0px" }}>
            <ChildContainer>
              <ChildContainerAux
                style={{ alignSelf: "center" }}
              >
                <IuguModalSpan>
                  {iuguResponseText}
                </IuguModalSpan>
              </ChildContainerAux>
              <BtnContainer>
                <Button
                  style={{ margin: "5px" }}
                  type="submit"
                  fullWidth={true}
                  variant="contained"
                  onClick={() => {
                    setShowIuguModal(false);
                  }}
                >
                  OK
                </Button>
              </BtnContainer>
            </ChildContainer>
          </Content>
        </FFModal>
      )}

    </ProtectedPage>
  );
};
