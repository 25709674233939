import React from "react";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";

import {
  Box as MuiBox,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import { spacing } from "@material-ui/system";

const Box = styled(MuiBox)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing);

export type ProtectedPageProps = {
  action?: React.ReactElement<any>,
  breadcrumbs: Array<{ name: string, to: string }>,
  title: string,
}

export const ProtectedPage: React.FC<ProtectedPageProps> = (props) => {

  return (
    <Box py={8} px={10}>
      <Helmet title={props.title} />



      <Grid justify="space-between" container={true} spacing={10}>
        <Grid item={true}>
          <Typography variant="h3" gutterBottom={true} display="inline">
            {props.title}
          </Typography>

          <Breadcrumbs mt={2}>
            <Link component={NavLink} exact={true} to="/">
              Início
            </Link>
            {
              props.breadcrumbs.map((breadcrumb, index: number) => (
                index < props.breadcrumbs.length - 1 ?
                  <Link component={NavLink} exact={true} to={breadcrumb.to}>
                    {breadcrumb.name}
                  </Link>
                  :
                  <Typography>{breadcrumb.name}</Typography>
              ))
            }
          </Breadcrumbs>
        </Grid>
        <Grid item={true}>
          {props.action}
        </Grid>
      </Grid>

      <Divider my={6} />

      {props.children}
    </Box>
  )
}
