import { fetchBanks } from "../services/ProfilesService";
import { FFTextField } from "./FFTextField";
import { spacing } from "@material-ui/system";
import React from "react";
import Select from "react-select"
import styled from "styled-components";

import {
  Card as MuiCard,
  CardContent,
  FormControl as MuiFormControl,
  Grid,
  InputLabel,
  Select as MuiSelect,
  Typography,
} from "@material-ui/core";

const Card = styled(MuiCard)(spacing);

const FormControl = styled(MuiFormControl)(spacing);

const customStyles = {
  input: (provided: any) => ({
    ...provided,
    border: "none",
    fontSize: 12,
  }),

  menu: (provided: any) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    zIndex: 9999,
  }),

  option: (provided: any, state: any) => ({
    ...provided,
    border: "1px solid #eee",
    boxShadow: "none",
    cursor: "pointer",
    fontSize: 16,
    textAlign: "left",
  }),

  container: (provided: any) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
  }),

  control: (provided: any) => ({
    ...provided,
    borderRadius: 5,
    paddingTop: 5,
    paddingBottom: 5,
    border: "1px solid #d6d6d6",
    cursor: "pointer",
    fontSize: "0.9rem",
    textAlign: "left",
  }),

  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: "none",
  }),

  placeholder: (provided: any) => {
    return {
      ...provided,
      color: "#000",
    };
  },
};

const BankAccountForm: React.FC<any> = (props: any) => {
  const [bankOptions, setBanksOptions] = React.useState([]);
  const [text, setText] = React.useState("")

  const onAccountTypeChange = (event: any) =>
    props.onDataChange({
      ...props.data,
      accountType: event.target.value,
    })

  const onBankCodeChange = (event: any) => {
    props.onDataChange({
      ...props.data,
      bankCode: event?.value,
    })
  }

  const onBranchNumberChange = (event: any) =>
    props.onDataChange({
      ...props.data,
      branchNumber: event.target.value,
    })

  const onChangeBranchDigitChange = (event: any) =>
    props.onDataChange({
      ...props.data,
      branchDigit: event.target.value,
    })

  const onAccountNumberChange = (event: any) =>
    props.onDataChange({
      ...props.data,
      accountNumber: event.target.value,
    })

  const onAccountDigitChange = (event: any) =>
    props.onDataChange({
      ...props.data,
      accountDigit: event.target.value,
    })

  React.useEffect(() => {
    (async () => {
      const banks = await fetchBanks();
      const autoCompleteOptions = banks?.data?.length
        ? banks.data.map((row: any) => {
          return {
            value: row.id,
            label: `${row.code} - ${row.name}`,
          };
        })
        : [];

      setBanksOptions(autoCompleteOptions);

      const selectedBankCode = props?.data?.bank?.code
      const selectedBankName = props?.data?.bank?.name
      const selectedBank = selectedBankCode + ' - ' + selectedBankName
      setText(selectedBank)
    })();
  }, []);

  return (
    props?.data && (
      <div>
        <Card mb={6}>
          <CardContent>
            <Typography
              variant="h4"
              gutterBottom={true}
              style={{ paddingTop: 16 }}
            >
              Dados Bancários
            </Typography>
            <Grid container={true} spacing={6} style={{ paddingTop: 8 }}>

              <Grid item={true} xs={3} style={{ paddingTop: 20 }}>
                <FormControl variant="outlined" fullWidth={true}>
                  <InputLabel>Tipo de Conta</InputLabel>
                  <MuiSelect
                    native={true}
                    fullWidth={true}
                    value={props?.data?.accountType || ""}
                    onChange={onAccountTypeChange}

                    error={!!props.errors?.accountType}
                    label="Tipo de Conta"
                  >
                    <option value="checking account">Corrente</option>
                    <option value="savings account">Poupança</option>
                    <option value="payment account">Pagamentos </option>
                  </MuiSelect>
                </FormControl>
              </Grid>
              <Grid item={true} xs={4} style={{ marginBottom: 15 }}>
                <InputLabel style={{ fontSize: 11, marginBottom: 2 }}>Código do Banco</InputLabel>
                <FormControl variant="outlined" fullWidth={true}>
                  <Select
                    styles={customStyles}
                    placeholder={text}
                    options={bankOptions}
                    onChange={onBankCodeChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container={true} spacing={6} style={{ paddingTop: 8 }}>
              <Grid item={true} xs={3}>
                <FFTextField
                  name="branchNumber"
                  label="Número da Agência"
                  value={props?.data?.branchNumber || ""}
                  fullWidth={true}
                  onChange={onBranchNumberChange}
                  variant="outlined"
                  my={2}
                  error={!!props.errors?.branchNumber}
                  helperText={props.errors?.branchNumber}
                />
              </Grid>
              <Grid item={true} xs={4}>
                <FFTextField
                  name="branchDigit"
                  label="Dígito da Agência"
                  value={props?.data?.branchDigit || ""}
                  fullWidth={true}
                  onChange={onChangeBranchDigitChange}
                  variant="outlined"
                  my={2}
                  error={!!props.errors?.branchDigit}
                  helperText={props.errors?.branchDigit}
                />
              </Grid>
            </Grid>
            <Grid container={true} spacing={6} style={{ paddingTop: 8 }}>
              <Grid item={true} xs={3}>
                <FFTextField
                  name="accountNumber"
                  label="Número da Conta"
                  value={props?.data?.accountNumber || ""}
                  fullWidth={true}
                  onChange={onAccountNumberChange}
                  error={!!props.errors?.accountNumber}
                  helperText={props.errors?.accountNumber}
                  variant="outlined"
                  my={2}
                />
              </Grid>
              <Grid item={true} xs={4}>
                <FFTextField
                  name="accountDigit"
                  label="Dígito da Conta"
                  value={props?.data?.accountDigit || ""}
                  fullWidth={true}
                  onChange={onAccountDigitChange}
                  error={!!props.errors?.accountDigit}
                  helperText={props.errors?.accountDigit}
                  variant="outlined"
                  my={2}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    )
  );
};

export default BankAccountForm;