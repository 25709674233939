import { spacing } from "@material-ui/system";
import { format, parseISO } from "date-fns";
import { ProfilesService } from "../../services";
import { ProtectedPage } from "../../components";
import { pt } from "date-fns/locale";
import { NavLink, useParams } from "react-router-dom";
import React from "react";
import styled from "styled-components";
import { WithdrawsService } from "../../services";

import {
    Button,
    Card as MuiCard,
    CardContent,
    Grid,
    Paper as MuiPaper,
    Typography,
} from "@material-ui/core";
import { getById } from "../../services/AdminService";

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

function DetailPage() {
    const [data, setData] = React.useState<any>([]);
    const { id, invoiceLink } = useParams<{ id: string, invoiceLink: any }>();

    React.useEffect(() => {
        (async () => {
            const report = await WithdrawsService.getById(id);
            setData(report.data);
        })();
    }, [id]);

    const getDetailPDF = async () => {
        const pdf = await WithdrawsService.generatePDF(id);

        const linkSource = `data:application/pdf;base64,${pdf.data}`;
        const downloadLink = document.createElement("a");
        const fileName = `"relatorio-vendas.pdf";`

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    return (
        <div>
            <Paper>
                <Card mb={6}>
                    <CardContent>
                        <Typography variant="h3" gutterBottom={true} style={{ marginBottom: "16px" }}>
                            Informações detalhadas
                        </Typography>

                        <Grid container={true} spacing={8} style={{ marginBottom: '30px' }}>
                            <Grid item={true} md={12}>
                                <Grid container={true} spacing={8} style={{ marginBottom: "16px" }}>
                                    {invoiceLink !== 'null' &&
                                        <Grid item={true} md={4}>
                                            <Typography variant="h6" style={{ marginBottom: '8px' }}>
                                                Nota Fiscal
                                            </Typography>
                                            <Typography variant="body1">
                                                {invoiceLink}
                                            </Typography>
                                        </Grid>
                                    }
                                    <Grid item={true} md={4}>
                                        <Typography variant="h6" style={{ marginBottom: '8px' }}>
                                            N° do relatório
                                        </Typography>
                                        <Typography variant="body1">
                                            {data.meta?.reportNumber}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container={true} spacing={8} style={{ marginBottom: "24px" }}>
                                    <Grid item={true} md={4}>
                                        <Typography variant="h6" style={{ marginBottom: '8px' }}>
                                            Data de Solicitação de Repasse/Saque
                                        </Typography>
                                        <Typography variant="body1">
                                            {data.meta?.date}
                                        </Typography>
                                    </Grid>
                                    <Grid item={true} md={4}>
                                        <Typography variant="h6" style={{ marginBottom: '8px' }}>
                                            Nome
                                        </Typography>
                                        <Typography variant="body1">
                                            {data.meta?.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item={true} md={4}>
                                        <Typography variant="h6" style={{ marginBottom: '8px' }}>
                                            CPF/CNPJ
                                        </Typography>
                                        <Typography variant="body1">
                                            {data.meta?.document}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Typography variant="h3" gutterBottom={true} style={{ marginBottom: "16px" }}>
                            Vendas Realizadas
                        </Typography>


                        {data.sales?.rows.map((row: any, index: number) => {
                            return (
                                <Grid container={true} spacing={8} style={{ marginBottom: '30px' }}>
                                    <Grid item={true} md={12}>
                                        <Grid container={true} spacing={8} style={{ marginBottom: "16px" }}>
                                            <Grid item={true} md={4}>
                                                <Typography variant="h6" style={{ marginBottom: '8px' }}>
                                                    Nº da Venda
                                                </Typography>
                                                <Typography variant="body1">
                                                    {row.saleNumber}
                                                </Typography>
                                            </Grid>
                                            <Grid item={true} md={4}>
                                                <Typography variant="h6" style={{ marginBottom: '8px' }}>
                                                    Data da Venda
                                                </Typography>
                                                <Typography variant="body1">
                                                    {row.saleDate}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container={true} spacing={8} style={{ marginBottom: "24px" }}>
                                            <Grid item={true} md={4}>
                                                <Typography variant="h6" style={{ marginBottom: '8px' }}>
                                                    Data da Realização da Aula
                                                </Typography>
                                                <Typography variant="body1">
                                                    {row.executionDate}
                                                </Typography>
                                            </Grid>
                                            {/*IF CPF*/}
                                            <Grid item={true} md={4}>
                                                <Typography variant="h6" style={{ marginBottom: '8px' }}>
                                                    Nome do Aluno
                                                </Typography>
                                                <Typography variant="body1">
                                                    {row.buyerName}
                                                </Typography>
                                            </Grid>
                                            <Grid item={true} md={4}>
                                                <Typography variant="h6" style={{ marginBottom: '8px' }}>
                                                    CPF do Aluno
                                                </Typography>
                                                <Typography variant="body1">
                                                    {row.buyerDocument}
                                                </Typography>
                                            </Grid>
                                            <Grid item={true} md={4}>
                                                <Typography variant="h6" style={{ marginBottom: '8px' }}>
                                                    Valor Bruto
                                                </Typography>
                                                <Typography variant="body1">
                                                    {row.grossValue}
                                                </Typography>
                                            </Grid>
                                            <Grid item={true} md={4}>
                                                <Typography variant="h6" style={{ marginBottom: '8px' }}>
                                                    Custo Intermediação
                                                </Typography>
                                                <Typography variant="body1">
                                                    {row.fee}
                                                </Typography>
                                            </Grid>
                                            <Grid item={true} md={4}>
                                                <Typography variant="h6" style={{ marginBottom: '8px' }}>
                                                    Valor líquido
                                                </Typography>
                                                <Typography variant="body1">
                                                    {row.liquidValue}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })}

                        <Typography variant="h3" gutterBottom={true} style={{ marginBottom: "16px" }}>
                            Outras Despesas
                        </Typography>

                        {data.expenses?.rows.map((row: any, index: number) => {
                            return (
                                <Grid container={true} spacing={8} style={{ marginBottom: '0' }}>
                                    <Grid item={true} md={12}>
                                        <Grid container={true} spacing={8} style={{ marginBottom: "16px" }}>
                                            <Grid item={true} md={4}>
                                                <Typography variant="h6" style={{ marginBottom: '8px' }}>
                                                    Data
                                                </Typography>
                                                <Typography variant="body1">
                                                    {row.date}
                                                </Typography>
                                            </Grid>
                                            <Grid item={true} md={4}>
                                                <Typography variant="h6" style={{ marginBottom: '8px' }}>
                                                    Descrição
                                                </Typography>
                                                <Typography variant="body1">
                                                    {row.description}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container={true} spacing={8} style={{ marginBottom: "0" }}>
                                            <Grid item={true} md={4}>
                                                <Typography variant="h6" style={{ marginBottom: '8px' }}>
                                                    Custo Intermediação
                                                </Typography>
                                                <Typography variant="body1">
                                                    {row.value}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })}
                        <Grid container={true} spacing={8} style={{ marginBottom: '30px', fontWeight: 700 }}>
                            <Grid item={true} md={4}>
                                <Typography variant="h6" style={{ marginBottom: '8px' }}>
                                    Total
                                </Typography>
                                <Typography variant="body1">
                                    {data.expenses?.sum.value}
                                </Typography>
                            </Grid>
                        </Grid>


                        <Typography variant="h3" gutterBottom={true} style={{ marginBottom: "16px" }}>
                            Resumo
                        </Typography>

                        <Grid container={true} spacing={8} style={{ marginBottom: '30px' }}>
                            <Grid item={true} md={12}>
                                <Grid container={true} spacing={8} style={{ marginBottom: "16px" }}>
                                    <Grid item={true} md={4}>
                                        <Typography variant="h6" style={{ marginBottom: '8px' }}>
                                            Data
                                        </Typography>
                                        <Typography variant="body1">
                                            {data.summary?.rows[0].date}
                                        </Typography>
                                    </Grid>
                                    <Grid item={true} md={4}>
                                        <Typography variant="h6" style={{ marginBottom: '8px' }}>
                                            Descrição
                                        </Typography>
                                        <Typography variant="body1">
                                            VALOR BRUTO TOTAL DAS VENDAS REALIZADAS
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container={true} spacing={8} style={{ marginBottom: "24px" }}>
                                    <Grid item={true} md={4}>
                                        <Typography variant="h6" style={{ marginBottom: '8px' }}>
                                            Valor
                                        </Typography>
                                        {data.summary?.rows.map((row: any, index: number) => {
                                            if (row.description === "VALOR BRUTO TOTAL DAS VENDAS REALIZADAS")
                                                return (
                                                    <Typography variant="body1">
                                                        {row.value}
                                                    </Typography>
                                                )
                                        })}
                                    </Grid>
                                    <Grid item={true} md={4}>
                                        <Typography variant="h6" style={{ marginBottom: '8px' }}>
                                            Total a Receber
                                        </Typography>
                                        <Typography variant="body1">
                                            {data.summary?.sum.value}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item={true} md={4}>
                            <Button variant="contained" onClick={() => getDetailPDF()}>
                                Exportar Relatório
                            </Button>
                        </Grid>

                    </CardContent>
                </Card>
            </Paper>
        </div>
    );
}

export const WithdrawsDetailPage = () => {
    return (
        <ProtectedPage
            title="Visualizar Detalhes"
            breadcrumbs={[
                { name: "Saques", to: "/withdraws" },
                { name: "Visualizar Detalhes", to: "/withdraws/:id/:invoiceLink/detail" },
            ]}
        >
            <Grid container={true} spacing={6}>
                <Grid item={true} xs={12}>
                    <DetailPage />
                </Grid>
            </Grid>
        </ProtectedPage>
    );
};
