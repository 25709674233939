import { BannersForm, ProtectedPage } from "../../components";
import { BannerService } from "../../services";

export const BannersRegisterFormPage = () => {
  const handleSubmit = async (form: any) => {
    await BannerService.create(form);
  };

  return (
    <ProtectedPage
      title="Cadastrar Banner"
      breadcrumbs={[
        { name: 'Banners', to: '/banners' },
        { name: 'Cadastrar Banner', to: '/banners/new' }
      ]}
    >
      <BannersForm
        buttonName="Salvar"
        formTitle="Cadastrar Banner"
        handleSubmit={handleSubmit}
        resetFields={true}
        successMessage="Banner Cadastrado com Sucesso!"
      />
    </ProtectedPage>
  );
};
