import { Alert as MuiAlert } from "@material-ui/lab";
import { FFTextField } from "../components";
import { spacing, SpacingProps } from "@material-ui/system";
import React from "react";
import styled from "styled-components";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper as MuiPaper,
  Typography,
} from "@material-ui/core";

const Alert = styled(MuiAlert)(spacing);

const Button = styled(MuiButton)<ButtonPropstype>(spacing);

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

interface ButtonPropstype extends SpacingProps {
  component?: string;
}

interface AdminFormProps {
  buttonName: string;
  data?: any;
  formTitle: string;
  handleSubmit: any;
  resetFields: boolean;
  successMessage: string;
}

export const AdminForm: React.FC<AdminFormProps> = ({
  buttonName,
  data,
  formTitle,
  handleSubmit,
  resetFields,
  successMessage
}) => {
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [enabled, setEnabled] = React.useState(true);
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [lastname, setLastname] = React.useState("");
  const [name, setName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [status, setStatus] = React.useState({ sent: false });

  React.useEffect(() => {
    setConfirmPassword(data?.confirmPassword || "");
    setEmail(data?.email || "");
    setEnabled(data?.enabled || true);
    setLastname(data?.lastname || "");
    setName(data?.name || "");
    setPassword(data?.password || "");
  }, [data]);

  const resetForm = () => {
    setConfirmPassword("");
    setEmail("");
    setEnabled(false);
    setLastname("");
    setName("");
    setPassword("");
  };

  const submitForm = async (event: any) => {
    event.preventDefault();

    const formData = {
      confirmPassword,
      email,
      enabled,
      lastname,
      name,
      password,
    }

    setSubmitting(true);

    try {
      await handleSubmit(formData);
      if (resetFields) {
        resetForm();
      }
      setStatus({ sent: true });
      setSubmitting(false);
      setErrors({});
    } catch (err: any) {
      if (err?.response?.status === 400) {
        setErrors(err?.response?.data)
      }

      setStatus({ sent: false });
      setSubmitting(false);
    }
  };

  const onConfirmPasswordChange = (event: any) => setConfirmPassword(event.target.value);
  const onEmailChange = (event: any) => setEmail(event.target.value);
  const onEnabledChange = (event: any) => setEnabled(event.target.checked);
  const onLastnameChange = (event: any) => setLastname(event.target.value);
  const onNameChange = (event: any) => setName(event.target.value);
  const onPasswordChange = (event: any) => setPassword(event.target.value);

  const checkboxControl = () => {
    return (
      <Checkbox
        checked={enabled}
        name="enabled"
        onChange={onEnabledChange}
      />
    )
  }

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h4" gutterBottom={true}>
          {formTitle}
        </Typography>

        {status && status.sent && (
          <Alert severity="success" my={3}>
            {successMessage}
          </Alert>
        )}

        {isSubmitting ? (
          <Box display="flex" justifyContent="center" my={6}>
            <CircularProgress />
          </Box>
        ) : (
          <Paper>
            <form onSubmit={handleSubmit}>
              <Grid item={true} md={6} style={{ marginBottom: '16px' }}>
                <FFTextField
                  error={!!errors?.name}
                  fullWidth={true}
                  helperText={errors?.name}
                  label="Nome"
                  my={2}
                  name="name"
                  onChange={onNameChange}
                  placeholder="Digite o primeiro nome"
                  value={name}
                  variant="outlined"
                />
              </Grid>
              <Grid item={true} md={6} style={{ marginBottom: '16px' }}>
                <FFTextField
                  error={!!errors?.lastname}
                  fullWidth={true}
                  helperText={errors?.lastname}
                  label="Sobrenome"
                  my={2}
                  name="lastname"
                  onChange={onLastnameChange}
                  placeholder="Digite o último nome"
                  value={lastname}
                  variant="outlined"
                />
              </Grid>
              <Grid item={true} md={6} style={{ marginBottom: '16px' }}>
                <FFTextField
                  error={!!errors?.email}
                  fullWidth={true}
                  helperText={errors?.email}
                  label="Email"
                  my={2}
                  name="email"
                  onChange={onEmailChange}
                  placeholder="Digite o email"
                  type="email"
                  value={email}
                  variant="outlined"
                />
              </Grid>
              <Grid item={true} md={6} style={{ marginBottom: '16px' }}>
                <FFTextField
                  error={!!errors?.password}
                  fullWidth={true}
                  helperText={errors?.password}
                  label="Senha"
                  my={2}
                  name="password"
                  onChange={onPasswordChange}
                  placeholder="Digite a senha"
                  type="password"
                  value={password}
                  variant="outlined"
                />
              </Grid>
              <Grid item={true} md={6} style={{ marginBottom: '16px' }}>
                <FFTextField
                  error={!!errors?.confirmPassword}
                  fullWidth={true}
                  helperText={errors?.confirmPassword}
                  label="Confirme sua senha"
                  my={2}
                  name="confirmPassword"
                  onChange={onConfirmPasswordChange}
                  placeholder="Confirme a senha"
                  type="password"
                  value={confirmPassword}
                  variant="outlined"
                />
              </Grid>
              <Grid item={true} md={6} style={{ marginBottom: "16px" }}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Status do Administrador</FormLabel>
                  <FormControlLabel
                    control={checkboxControl()}
                    label={enabled ? "Ativo" : "Inativo"}
                  />
                </FormControl>
              </Grid>
              <Button
                onClick={submitForm}
                type="submit"
                variant="contained"
                color="primary"
                mt={4}
              >
                {buttonName}
              </Button>
            </form>
          </Paper>
        )}
      </CardContent>
    </Card>
  )
}

