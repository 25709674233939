import { CategoryService } from "../../services";
import { format, parseISO } from "date-fns";
import { NavLink } from "react-router-dom";
import { Chip, FFTextField, ProtectedPage } from "../../components";
import { pt } from "date-fns/locale";
import { spacing } from "@material-ui/system";
import { useState, MouseEvent, useEffect } from "react";
import styled from "styled-components";

import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@material-ui/icons";

const Paper = styled(MuiPaper)(spacing);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      margin: theme.spacing(1),
      minWidth: 140,
    },
  })
);

interface SearchOptions {
  page: number;
  group: string;
}

function EnhancedTable() {
  const [data, setData] = useState<any>();
  const [deleteId, setDeleteId] = useState<any>();
  const [name, setName] = useState<any>();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [searchOptions, setSearchOptions] = useState<SearchOptions>({
    page: 0,
    group: '',
  });

  const { page, group } = searchOptions;

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setSearchOptions({ ...searchOptions, page: newPage });
  };

  const handleRemove = async () => {
    await CategoryService.deleteById(deleteId);
    handleClose();
    const resList = await CategoryService.list(page, 6);
    setData(resList.data);
  };

  const handleRemoveAction = (id: any, name: any) => {
    setOpen(true);
    setDeleteId(id);
    setName(name);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteId(null);
  };

  const onFilterByGroupChange = async (event: any) => {
    setSearchOptions({
      page: 0,
      group: event.target.value,
    });
  };

  useEffect(() => {
    (async () => {
      const resList = await CategoryService.list(page, 6, group);
      setData(resList.data);
    })();
  }, [group, page]);

  return (
    <div>
      <Grid container={true} spacing={8} justify="flex-end">
        <Grid item={true}>
          <FFTextField
            className={classes.textField}
            label='Pesquisar por Grupo'
            onChange={onFilterByGroupChange}
            placeholder='Digite o grupo'
            value={group}
          />
        </Grid>
      </Grid>

      <Paper>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle id="alert-dialog-title">{`Deseja excluir ${name}?`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Tem certeza que deseja excluir?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRemove} color="primary">
              Sim
            </Button>
            <Button onClick={handleClose} color="primary" autoFocus={true}>
              Não
            </Button>
          </DialogActions>
        </Dialog>

        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableCell align="left">Preview</TableCell>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Grupo</TableCell>
              <TableCell align="center">Ordem</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Criação / Atualização</TableCell>
              <TableCell align="right">Ações</TableCell>
            </TableHead>
            <TableBody>
              {data?.rows?.map((row: any, index: number) => {
                return (
                  <TableRow
                    hover={true}
                    key={`${row.id}-${index}`}
                    tabIndex={1}
                  >
                    <TableCell align="left" component="th" scope="row">
                      <div
                        style={{
                          alignItems: "left",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <img
                          alt="banners image"
                          height="48px"
                          src={row?.image?.location}
                          width="58px"
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      <Typography>
                        {row.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      <Typography>
                        {row.group.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      <Typography>
                        {row.order}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.enabled && <Chip color='#4aff4a' message='Ativo' />}
                      {!row.enabled && <Chip color='#c9c9c9' message='Inativo' />}
                    </TableCell>
                    <TableCell align="center">
                      <Typography>
                        {format(parseISO(row.createdAt), "Pp", {
                          locale: pt,
                        })}
                        <br />
                        {format(parseISO(row.updatedAt), "Pp", {
                          locale: pt,
                        })}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        aria-label="edit"
                        component={NavLink}
                        to={`categories/${row.id}/edit`}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="details"
                        component={NavLink}
                        exact={true}
                        to={`categories/${row.id}/categories-detail`}
                      >
                        <RemoveRedEyeIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={(id) => handleRemoveAction(row.id, row.name)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[6]}
          component="div"
          count={data?.count}
          rowsPerPage={6}
          page={page}
          onChangePage={handleChangePage}
        />
      </Paper>
    </div>
  );
}

export const CategoriesIndexPage = () => {

  return (
    <ProtectedPage
      title="Categorias"
      breadcrumbs={[{ name: 'Categorias', to: '/categories' }]}
      action={
        <Button
          color="primary"
          component={NavLink}
          exact={true}
          startIcon={<AddIcon />}
          to="/categories/new"
          variant="contained"
        >
          Cadastrar Categoria
        </Button>
      }
    >
      <Grid container={true} spacing={6}>
        <Grid item={true} xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </ProtectedPage>
  );
};
