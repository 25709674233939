import {
  Card as MuiCard,
  CardContent,
  FormControl as MuiFormControl,
  Grid,
  InputLabel,
  Select,
  Typography,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { spacing } from "@material-ui/system";
import React from "react";
import styled from "styled-components";
import { FFTextField } from "./FFTextField";

const Card = styled(MuiCard)(spacing);

const FormControl = styled(MuiFormControl)(spacing);

const UsersForm: React.FC<any> = (props: any) => {
  return (
    <div>
      <Card mb={6}>
        <CardContent>
          <Typography variant="h4" gutterBottom={true}>
            Informações Pessoais
          </Typography>
          <Grid container={true} spacing={6}>
            <Grid item={true} xs={6}>
              <FFTextField
                name="name"
                label="Nome"
                value={props?.data?.name || ""}
                fullWidth={true}
                onChange={(event) =>
                  props.onDataChange({
                    ...props.data,
                    name: event.target.value,
                  })
                }
                variant="outlined"
                my={2}
                error={!!props.errors?.name}
                helperText={props.errors?.name}
              />
            </Grid>
            <Grid item={true} xs={6}>
              <FFTextField
                name="surname"
                label="Sobrenome"
                value={props?.data?.lastname || ""}
                fullWidth={true}
                onChange={(event) =>
                  props.onDataChange({
                    ...props.data,
                    lastname: event.target.value,
                  })
                }
                variant="outlined"
                my={2}
                error={!!props.errors?.lastname}
                helperText={props.errors?.lastname}
              />
            </Grid>
          </Grid>

          <FFTextField
            name="email"
            label="E-mail"
            value={props?.data?.email || ""}
            fullWidth={true}
            type="email"
            variant="outlined"
            my={2}
            disabled={true}
          />

          <Grid container={true} spacing={6} style={{ paddingTop: 8 }}>
            <Grid item={true} xs={6}>
              <FFTextField
                name="document"
                label="CPF"
                value={props?.data?.document || ""}
                fullWidth={true}
                variant="outlined"
                disabled={true}
                error={!!props.errors?.document}
                helperText={props.errors?.document}
              />
            </Grid>
            <Grid item={true} xs={4}>
              <KeyboardDatePicker
                autoOk={true}
                fullWidth={true}
                variant="inline"
                inputVariant="outlined"
                label="Data de nascimento"
                format="dd/MM/yyyy"
                value={props?.data?.birthdate || ""}
                onChange={(date: any) =>
                  props.onDataChange({
                    ...props.data,
                    birthdate: date,
                  })
                }
                error={!!props.errors?.birthdate}
                helperText={props.errors?.birthdate}
              />
            </Grid>
            <Grid item={true} xs={2}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Gênero</InputLabel>
                <Select
                  native={true}
                  fullWidth={true}
                  value={props?.data?.gender || ""}
                  onChange={(event) =>
                    props.onDataChange({
                      ...props.data,
                      gender: event.target.value,
                    })
                  }
                  label="Gênero"
                >
                  <option value="male">Masculino</option>
                  <option value="female">Feminino</option>
                  <option value="other">Outro</option>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Typography
            variant="h4"
            gutterBottom={true}
            style={{ paddingTop: 16 }}
          >
            Endereço
          </Typography>
          <Grid container={true} spacing={6}>
            <Grid item={true} xs={10}>
              <FFTextField
                name="street"
                label="Logradouro"
                value={props?.data?.address?.street || ""}
                fullWidth={true}
                onChange={(event) =>
                  props.onDataChange({
                    ...props.data,
                    address: {
                      ...props.data.address,
                      street: event.target.value,
                    },
                  })
                }
                variant="outlined"
                my={2}
                error={!!props.errors?.street}
                helperText={props.errors?.street}
              />
            </Grid>
            <Grid item={true} xs={2}>
              <FFTextField
                name="addressNumber"
                label="Número"
                value={props?.data?.address?.number || ""}
                fullWidth={true}
                onChange={(event) =>
                  props.onDataChange({
                    ...props.data,
                    address: {
                      ...props.data.address,
                      number: event.target.value,
                    },
                  })
                }
                variant="outlined"
                my={2}
                error={!!props.errors?.number}
                helperText={props.errors?.number}
              />
            </Grid>
          </Grid>

          <Grid container={true} spacing={6}>
            <Grid item={true} xs={6}>
              <FFTextField
                name="district"
                label="Bairro"
                value={props?.data?.address?.district || ""}
                fullWidth={true}
                onChange={(event) =>
                  props.onDataChange({
                    ...props.data,
                    address: {
                      ...props.data.address,
                      district: event.target.value,
                    },
                  })
                }
                error={!!props.errors?.district}
                helperText={props.errors?.district}
                type="email"
                variant="outlined"
                my={2}
              />
            </Grid>
            <Grid item={true} xs={4}>
              <FFTextField
                name="city"
                label="Cidade"
                value={props?.data?.address?.city || ""}
                fullWidth={true}
                onChange={(event) =>
                  props.onDataChange({
                    ...props.data,
                    address: {
                      ...props.data.address,
                      city: event.target.value,
                    },
                  })
                }
                error={!!props.errors?.city}
                helperText={props.errors?.city}
                variant="outlined"
                my={2}
              />
            </Grid>
            <Grid item={true} xs={2}>
              <FFTextField
                name="state"
                label="Estado"
                value={props?.data?.address?.state || ""}
                fullWidth={true}
                onChange={(event) =>
                  props.onDataChange({
                    ...props.data,
                    address: {
                      ...props.data.address,
                      state: event.target.value,
                    },
                  })
                }
                error={!!props.errors?.state}
                helperText={props.errors?.state}
                variant="outlined"
                my={2}
              />
            </Grid>
          </Grid>

          <Grid container={true} spacing={6}>
            <Grid item={true} xs={8}>
              <FFTextField
                name="complement"
                label="Complemento"
                value={props?.data?.address?.complement || ""}
                fullWidth={true}
                onChange={(event) =>
                  props.onDataChange({
                    ...props.data,
                    address: {
                      ...props.data.address,
                      complement: event.target.value,
                    },
                  })
                }
                variant="outlined"
                my={2}
              />
            </Grid>
            <Grid item={true} xs={4}>
              <FFTextField
                name="zipcode"
                label="CEP"
                value={props?.data?.address?.zipcode || ""}
                fullWidth={true}
                onChange={(event) =>
                  props.onDataChange({
                    ...props.data,
                    address: {
                      ...props.data.address,
                      zipcode: event.target.value,
                    },
                  })
                }
                variant="outlined"
                my={2}
                error={!!props.errors?.zipcode}
                helperText={props.errors?.zipcode}
              />
            </Grid>
          </Grid>

          <Typography
            variant="h4"
            gutterBottom={true}
            style={{ padding: "8px 0" }}
          >
            Telefone
          </Typography>

          <Grid container={true} spacing={6}>
            <Grid item={true} xs={2}>
              <FFTextField
                name="DDI"
                label="DDI"
                value={props?.data?.phone.countryCode || ""}
                fullWidth={true}
                onChange={(event) =>
                  props.onDataChange({
                    ...props.data,
                    phone: {
                      ...props.data.phone,
                      countryCode: event.target.value,
                    },
                  })
                }
                error={!!props.errors?.countryCode}
                helperText={props.errors?.countryCode}
                variant="outlined"
                my={2}
              />
            </Grid>
            <Grid item={true} xs={2}>
              <FFTextField
                name="DDD"
                label="DDD"
                value={props?.data?.phone.areaCode || ""}
                fullWidth={true}
                onChange={(event) =>
                  props.onDataChange({
                    ...props.data,
                    phone: {
                      ...props.data.phone,
                      areaCode: event.target.value,
                    },
                  })
                }
                error={!!props.errors?.areaCode}
                helperText={props.errors?.areaCode}
                variant="outlined"
                my={2}
              />
            </Grid>
            <Grid item={true} xs={4}>
              <FFTextField
                name="phoneNumber"
                label="Telefone"
                value={props?.data?.phone.number || ""}
                fullWidth={true}
                onChange={(event) =>
                  props.onDataChange({
                    ...props.data,
                    phone: {
                      ...props.data.phone,
                      number: event.target.value,
                    },
                  })
                }
                variant="outlined"
                my={2}
                error={!!props.errors?.number}
                helperText={props.errors?.number}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default UsersForm;
