import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { useEffect, useState, VFC } from "react";
import FFModal from ".";

interface DeleteConfirmationModalProps {
    onClose: (remove: boolean) => void;
    isOpen: boolean;
    title?: string;
}

export const DeleteConfirmationModal: VFC<DeleteConfirmationModalProps> = ({
    onClose,
    isOpen,
    title,
}) => {
    const [open, setOpen] = useState<boolean>(isOpen);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen])

    const handleClose = (remove: boolean): void => {
        setOpen(false);
        onClose(remove);
    }

    return (
        <Dialog open={open} onClose={() => handleClose(false)}>
            <DialogTitle id="alert-dialog-title">{`Deseja excluir?`}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Tem certeza que deseja excluir {title}?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(true)} color="primary">
                    Sim
                </Button>
                <Button onClick={() => handleClose(false)} color="primary" autoFocus={true}>
                    Não
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FFModal;
