import { CategoriesForm, ProtectedPage } from "../../components";
import { CategoryService } from "../../services";
import { useParams } from "react-router-dom";
import React from "react";

export const CategoriesEditFormPage = () => {
  const [data, setData] = React.useState([]);
  const { id } = useParams<{ id: any }>();

  React.useEffect(() => {
    (async () => {
      const resList = await CategoryService.getById(id);
      setData(resList.data);
    })();
  }, []);

  const handleSubmit = async (form: any) => {
    await CategoryService.edit(id, form);
  };

  return (
    <ProtectedPage
      title="Editar Categoria"
      breadcrumbs={[
        { name: 'Categorias', to: '/categories' },
        { name: 'Editar Categoria', to: '/categories/:id/edit' }
      ]}
    >

      <CategoriesForm
        data={data}
        buttonName="Atualizar"
        formTitle="Editar Categoria"
        handleSubmit={handleSubmit}
        resetFields={false}
        successMessage="Categoria Atualizada com Sucesso!" />
    </ProtectedPage>

  );
};
