import { api } from "./BackendService";

export type CnpjStatus = 'pending' | 'approved' | 'rejected';

export interface Company {
    addressId: string;
    cnpjStatus: CnpjStatus;
    createdAt: string;
    document: string;
    id: string;
    name: string;
    phoneId: string;
    updatedAt: string;
}

export const list = () => api.get("/companies/cnpj-verify-status/");
export const edit = (id: string, data: any) => api.put(`/companies/${id}`, data);