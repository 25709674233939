import { BaseList } from "../types/api";
import { api } from "./BackendService";

export type TargetGroup = 'company' | 'trainer' | 'regular';

export interface NotificationBody {
    title: string;
    subtitle: string;
    scheduledAt: Date;
    url: string;
    targetGroup: TargetGroup;
    icon?: string;
    template?: string;
}

export interface Notification {
    id: string;
    title: string;
    description: string;
    scheduledAt: string;
    targetGroup: TargetGroup;
    template: string;
    icon: string;
    url: string;
    createdAt: string;
    updatedAt: string;
}

export const getById = (id: string) => api.get<Notification>(`/notifications/${id}`);
export const list = (page: number, pageSize: number) => api.get<BaseList<Notification>>("/notifications", { params: { page: page, pageSize: pageSize } });
export const create = (data: NotificationBody) => api.post('/notifications', data);
export const update = (id: string, data: NotificationBody) => api.put(`/notifications/${id}`, data);
export const deleteById = (id: string) => api.delete(`/notifications/${id}`);
