import { NavLink } from "react-router-dom";
import { pt } from "date-fns/locale";
import { useState, useEffect } from "react";
import styled from "styled-components";

import {
  Breadcrumbs as MuiBreadcrumbs,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";

import {
  Delete as DeleteIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@material-ui/icons";

import { format, parseISO } from "date-fns";
import { LessonsService } from "../../services";
import { Chip, FFTextField, ProtectedPage } from "../../components";
import { spacing } from "@material-ui/system";
import { DeleteConfirmationModal } from "../../components/Modal/DeleteConfirmationModal";
import { useAlert } from "../../hooks/useAlert";

const Paper = styled(MuiPaper)(spacing);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      margin: theme.spacing(1),
      minWidth: 140,
    },
  })
);

interface SearchOptions {
  page: number;
  filterByCategory: string;
  filterByLessonName: string;
  filterByProfessionalUsername: string;
}

function EnhancedTable() {
  const [data, setData] = useState<any>();
  const [itemToRemove, setItemToRemove] = useState<any>();
  const classes = useStyles();
  const [searchOptions, setSearchOptions] = useState<SearchOptions>({
    page: 0,
    filterByCategory: '',
    filterByLessonName: '',
    filterByProfessionalUsername: '',
  });
  const { alertOptions, showAlert } = useAlert();

  const { page, filterByCategory, filterByLessonName, filterByProfessionalUsername } = searchOptions;

  const fetchLessons = async () => {
      const resList = await LessonsService.list(page, 6, filterByCategory, filterByLessonName, filterByProfessionalUsername);
      setData(resList.data);
  }

  useEffect(() => {
    fetchLessons();
  }, [filterByCategory, filterByLessonName, filterByProfessionalUsername, page]);

  const onFilterChange = (key: string, value: string) => {
    setSearchOptions({
      ...searchOptions,
      page: 0,
      [key]: value,
    });
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setSearchOptions({ ...searchOptions, page: newPage });
  };

  const handleDeleteModalClose = (remove: boolean) => {
      if (remove && itemToRemove) {
          LessonsService.deleteById(itemToRemove.id)
              .then(() => {
                  showAlert('Removido com sucesso!', 'success');
                  fetchLessons();
              })
              .catch(() => showAlert("Não foi possível desativar a aula", "error"));
      }

      setItemToRemove(undefined);
  }

  return (
    <div>
      <DeleteConfirmationModal
          isOpen={!!itemToRemove}
          onClose={handleDeleteModalClose}
          title={itemToRemove?.title}
      />
      <Grid container={true} spacing={8} justify="flex-end">
        <Grid item={true}>
          <FFTextField
            className={classes.textField}
            label='Pesquisar por Categoria'
            onChange={(event) => onFilterChange('filterByCategory', event.target.value)}
            placeholder='Digite a categoria'
            value={filterByCategory}
          />
        </Grid>
        <Grid item={true}>
          <FFTextField
            className={classes.textField}
            label='Pesquisar por Nome'
            onChange={(event) => onFilterChange('filterByLessonName', event.target.value)}
            placeholder='Digite o nome'
            value={filterByLessonName}
          />
        </Grid>
        <Grid item={true}>
          <FFTextField
            className={classes.textField}
            label='Pesquisar por Usuário'
            onChange={(event) => onFilterChange('filterByProfessionalUsername', event.target.value)}
            placeholder='Digite o usuário'
            value={filterByProfessionalUsername}
          />
        </Grid>
      </Grid>

      <Grid container={true} spacing={8}>
        <Grid item={true} xs={12}>
          <Paper>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableCell>Aula / Categoria</TableCell>
                  <TableCell>Fornecedor</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Criação / Atualização</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableHead>
                <TableBody>
                  {data?.rows?.map((row: any, index: number) => {
                    return (
                      <TableRow
                        hover={true}
                        key={`${row.id}-${index}`}
                        tabIndex={-1}
                      >
                        <TableCell component="th" scope="row">
                          <Typography>
                            <strong>{row?.name}</strong>
                            <br />
                            {row?.category.name}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography>
                            @{row?.profile.username}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.enabled && <Chip color='#4aff4a' message='Ativo' />}
                          {!row.enabled && <Chip color='#c9c9c9' message='Inativo' />}
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {format(parseISO(row.createdAt), "Pp", {
                              locale: pt,
                            })}
                            <br />
                            {format(parseISO(row.updatedAt), "Pp", {
                              locale: pt,
                            })}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="details"
                            component={NavLink}
                            exact={true}
                            to={`lessons/${row.id}/lessons-detail`}
                          >
                            <RemoveRedEyeIcon />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            title="Desativar aula"
                            onClick={() => setItemToRemove(row)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[6]}
              component="div"
              count={data?.count}
              rowsPerPage={6}
              page={page}
              onChangePage={handleChangePage}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export const LessonsIndexPage = () => {
  return (

    <ProtectedPage
      title="Aulas"
      breadcrumbs={[{
        name: 'Aulas', to: '/lessons'
      }]}
    >
      <Grid container={true} spacing={6}>
        <Grid item={true} xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </ProtectedPage>
  );
};
