import { Alert as MuiAlert } from "@material-ui/lab";
import { FFTextField } from "../components";
import { GroupService } from "../services";
import { spacing, SpacingProps } from "@material-ui/system";
import React from "react";
import styled from "styled-components";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";

import {
  Delete as DeleteIcon,
} from "@material-ui/icons";

const Alert = styled(MuiAlert)(spacing);

const Button = styled(MuiButton)<ButtonPropstype>(spacing);

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

interface ButtonPropstype extends SpacingProps {
  component?: string;
}

interface CategoriesFormProps {
  buttonName: string;
  data?: any;
  formTitle: string;
  handleSubmit: any;
  resetFields: boolean;
  successMessage: string;
}

export const CategoriesForm: React.FC<CategoriesFormProps> = ({
  buttonName,
  data,
  formTitle,
  handleSubmit,
  resetFields,
  successMessage
}) => {
  const [color, setColor] = React.useState("");
  const [enabled, setEnabled] = React.useState(true);
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [groups, setGroups] = React.useState<any>({});
  const [image, setImage] = React.useState<any>({});
  const [imageBlob, setImageBlob] = React.useState({});
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [name, setName] = React.useState("");
  const [order, setOrder] = React.useState("");
  const [selected, setSelected] = React.useState("91059ed7-c8fe-4328-9cd1-11f0fa78af94");
  const [status, setStatus] = React.useState({ sent: false });

  React.useEffect(() => {
    (async () => {
      const resList = await GroupService.get();
      setGroups(resList.data);
    })();
  }, [data]);

  React.useEffect(() => {
    setColor(data?.color || "");
    setEnabled(data?.enabled || true);
    setImage(data?.image || {});
    setImageBlob(data?.image ? data.image.location : "");
    setName(data?.name || "");
    setOrder(data?.order || 0);
    setSelected(data?.groupId || "91059ed7-c8fe-4328-9cd1-11f0fa78af94");
  }, [data]);

  const resetForm = () => {
    setColor("");
    setEnabled(false);
    setImage({});
    setImageBlob({})
    setName("");
    setOrder("");
  };

  const submitForm = async (event: any) => {
    event.preventDefault();

    const form = {
      color,
      enabled,
      groupId: selected,
      image: image.data,
      name,
      order,
    }

    setSubmitting(true);

    try {
      await handleSubmit(form);
      if (resetFields) {
        resetForm();
      }
      setStatus({ sent: true });
      setSubmitting(false);
      setErrors({})
    } catch (err: any) {
      if (err?.response?.status === 400) {
        setErrors(err?.response?.data)
      }

      setStatus({ sent: false });
      setSubmitting(false);
    }
  };

  const handleInputFileChangeIcon = (event: any) => {
    const { files } = event.target;

    if (files.length) {
      const file = files[0]
      const blob = URL.createObjectURL(file);
      setImageBlob(blob);

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const img = {
          name: file.name,
          type: file.type,
          data: reader.result
        }
        setImage(img);
      }
    }
  };

  const handleRemoveImage = () => {
    setImageBlob("");
    setImage("");
  }

  const onColorChange = (event: any) => setColor(event.target.value);
  const onEnabledChange = (event: any) => setEnabled(event.target.checked);
  const onNameChange = (event: any) => setName(event.target.value);
  const onOrderChange = (event: any) => setOrder(event.target.value);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected((event.target as HTMLInputElement).value);
  };

  const radioControl = (radioKey: any, radioValue: any, radioName: any) => {
    return (
      <Radio key={radioKey} value={radioValue} name={radioName} />
    )
  }

  const groupsRadios = (groupId: any, groupName: any): any => {
    return (
      <FormControlLabel
        control={radioControl(groupId, groupId, groupName)}
        label={groupName}
        labelPlacement="end"
      />
    )
  }

  const checkboxControl = () => {
    return (
      <Checkbox
        checked={enabled}
        name="enabled"
        onChange={onEnabledChange}
      />
    )
  }

  const groupValidation = () => {
    if (selected === "")
      return (
        <Typography
          variant='caption'
          gutterBottom={true}
          color='error'
        >
          Grupo não pode ser vazio
        </Typography>
      )
  }

  const imageValidation = () => {
    if (imageBlob === "")
      return (
        <Typography
          variant='caption'
          gutterBottom={true}
          color='error'
        >
          Imagem não pode ser vazia
        </Typography>
      )
  }

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h4" gutterBottom={true}>
          {formTitle}
        </Typography>

        {status && status.sent && (
          <Alert severity="success" my={3}>
            {successMessage}
          </Alert>
        )}

        {isSubmitting ? (
          <Box display="flex" justifyContent="center" my={6}>
            <CircularProgress />
          </Box>
        ) : (
          <Paper>
            <form onSubmit={handleSubmit}>
              <Grid item={true} md={6} style={{ marginBottom: '16px' }}>
                <FFTextField
                  error={!!errors?.name}
                  fullWidth={true}
                  helperText={errors?.name}
                  label="Nome da categoria"
                  my={2}
                  name="name"
                  onChange={onNameChange}
                  placeholder="Digite o nome da categoria"
                  value={name}
                  variant="outlined"
                />
              </Grid>
              <Grid item={true} md={6} style={{ marginBottom: '16px' }}>
                <FFTextField
                  error={!!errors?.color}
                  fullWidth={true}
                  helperText={errors?.color}
                  inputProps={{ maxLength: 7 }}
                  label="Cor"
                  my={2}
                  name="color"
                  onChange={onColorChange}
                  placeholder="Digite a cor em hexadecimal"
                  value={color}
                  variant="outlined"
                />
              </Grid>
              <Grid item={true} md={6} style={{ marginBottom: "16px" }}>
                <FFTextField
                  error={!!errors?.order}
                  fullWidth={true}
                  helperText={errors?.order}
                  label="Ordem do grupo"
                  my={2}
                  name="order"
                  onChange={onOrderChange}
                  placeholder="Digite a ordem do grupo"
                  type="number"
                  value={order}
                  variant="outlined"
                />
              </Grid>
              <Grid item={true} md={6} style={{ marginBottom: '16px' }}>
                <FormLabel component="legend" style={{ marginBottom: '16px' }}>Imagem do Categoria</FormLabel>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  multiple={true}
                  type="file"
                  onChange={handleInputFileChangeIcon}

                />
                <label htmlFor="raised-button-file">
                  <Button variant="contained" color="primary" component="span">
                    Adicionar Imagem
                  </Button>
                </label>

                {imageBlob && (
                  <div
                    style={{ alignItems: "center", display: "flex", flexDirection: "column" }}
                  >
                    <img
                      alt="background image"
                      height="250px"
                      src={`${imageBlob}`}
                      width="250px"
                    />
                    <IconButton onClick={handleRemoveImage}>
                      <DeleteIcon />
                      <Typography variant="caption" gutterBottom={true} color="error">
                        Remover imagem
                      </Typography>
                    </IconButton>
                  </div>
                )}
              </Grid>
              <Grid item={true} md={6} style={{ marginBottom: '16px' }}>
                {imageValidation()}
              </Grid>
              <Grid item={true} md={6} style={{ marginBottom: '16px' }}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Grupos</FormLabel>
                  <RadioGroup
                    aria-label="groups"
                    name="groups"
                    value={selected}
                    onChange={handleRadioChange}
                  >
                    {groups?.rows?.map((row: any) => (groupsRadios(row.id, row.name)))}
                  </RadioGroup>
                  {groupValidation()}
                </FormControl>
              </Grid>
              <Grid item={true} md={6} style={{ marginBottom: "16px" }}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Status da Categoria</FormLabel>
                  <FormControlLabel
                    control={checkboxControl()}
                    label={enabled ? "Ativo" : "Inativo"}
                  />
                </FormControl>
              </Grid>
              <Button
                onClick={submitForm}
                type="submit"
                variant="contained"
                color="primary"
                mt={4}
              >
                {buttonName}
              </Button>
            </form>
          </Paper>
        )}
      </CardContent>
    </Card>
  )
}