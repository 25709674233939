import { Alert as MuiAlert } from "@material-ui/lab";
import { FFTextField } from ".";
import { spacing, SpacingProps } from "@material-ui/system";
import { useEffect } from "react";
import React from "react";
import styled from "styled-components";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper as MuiPaper,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";

const Alert = styled(MuiAlert)(spacing);

const Button = styled(MuiButton)<ButtonPropstype>(spacing);

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

interface ButtonPropstype extends SpacingProps {
  component?: string;
}
interface GroupsFromProps {
  buttonName: string;
  data?: any;
  formTitle: string;
  handleSubmit: any;
  resetFields: boolean;
  successMessage: string;
}

const GroupsForm: React.FC<GroupsFromProps> = ({
  buttonName,
  data,
  formTitle,
  handleSubmit,
  resetFields,
  successMessage,
}) => {
  const [description, setDescription] = React.useState("");
  const [enabled, setEnabled] = React.useState(false);
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [name, setName] = React.useState("");
  const [order, setOrder] = React.useState("");
  const [status, setStatus] = React.useState({ sent: false });
  const [template, setTemplate] = React.useState("presential");
  const [validations, setValidations] = React.useState<{ [key: string]: string }>({});

  useEffect(() => {
    setDescription(data?.description || "");
    setEnabled(data?.enabled || false);
    setName(data?.name || "");
    setOrder(data?.order || 0);
    setTemplate(data?.template || "presential");
  }, [data]);

  const onDescriptionChange = (e: any) => setDescription(e.target.value)
  const onEnabledChange = (event: any) => setEnabled(event.target.checked);
  const onNameChange = (event: any) => setName(event.target.value);
  const onOrderChange = (event: any) => setOrder(event.target.value);
  const onTemplateChange = (event: any) => setTemplate(event.target.value);

  const submitForm = async (e: any) => {
    e.preventDefault();
    const formData = {
      description,
      enabled,
      name,
      order,
      template,
    };
    setSubmitting(true);

    try {
      await handleSubmit(formData);
      if (resetFields) {
        resetForm();
      }
      setStatus({ sent: true });
      setSubmitting(false);
      setErrors({});
    } catch (err: any) {
      console.log(err?.response?.data)
      if (err?.response?.status === 400) {
        setErrors(err?.response?.data);
      }

      setStatus({ sent: false });
      setSubmitting(false);
    }
  };

  const resetForm = () => {
    setName("");
    setDescription("");
    setTemplate("presential");
    setEnabled(false);
    setOrder("");
  };

  const templateValidation = () => {
    if (template === "")
      return (
        <Typography
          variant='caption'
          gutterBottom={true}
          color='error'
        >
          Template não pode ser vazio
        </Typography>
      )
  }

  const checkboxControl = () => {
    return (
      <Checkbox
        checked={enabled}
        name="enabled"
        onChange={onEnabledChange}
      />
    )
  }

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h4" gutterBottom={true}>
          {formTitle}
        </Typography>

        {status && status.sent && (
          <Alert severity="success" my={3}>
            {successMessage}
          </Alert>
        )}

        {isSubmitting ? (
          <Box display="flex" justifyContent="center" my={6}>
            <CircularProgress />
          </Box>
        ) : (
          <Paper>
            <form onSubmit={handleSubmit}>
              <Grid item={true} md={6} style={{ marginBottom: "16px" }}>
                <FFTextField
                  error={!!errors?.name}
                  fullWidth={true}
                  helperText={errors?.name}
                  label="Nome do grupo"
                  my={2}
                  name="name"
                  onChange={onNameChange}
                  placeholder="Digite o nome do grupo"
                  value={name}
                  variant="outlined"
                />
              </Grid>
              <Grid item={true} md={6} style={{ marginBottom: "16px" }}>
                <FFTextField
                  error={!!errors?.description}
                  fullWidth={true}
                  helperText={errors?.description}
                  label="Descrição do grupo"
                  my={2}
                  name="description"
                  onChange={onDescriptionChange}
                  placeholder="Digite a descrição do grupo"
                  value={description}
                  variant="outlined"
                />
              </Grid>
              <Grid item={true} md={6} style={{ marginBottom: "16px" }}>
                <FFTextField
                  error={!!errors?.order}
                  fullWidth={true}
                  helperText={errors?.order}
                  label="Ordem do grupo"
                  my={2}
                  name="order"
                  onChange={onOrderChange}
                  placeholder="Digite a ordem do grupo"
                  type="number"
                  value={order}
                  variant="outlined"
                />
              </Grid>
              <Grid item={true} md={12} style={{ marginBottom: "16px" }}>
                <Grid style={{ marginBottom: "16px" }}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Template</FormLabel>
                    <RadioGroup
                      aria-label="templates"
                      name="templates"
                      value={template}
                      onChange={onTemplateChange}
                    >
                      <FormControlLabel
                        value="presential"
                        control={<Radio color="primary" />}
                        label="Presential"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="video"
                        control={<Radio color="primary" />}
                        label="Video"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="court"
                        control={<Radio color="primary" />}
                        label="Court"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                    {templateValidation()}
                  </FormControl>
                </Grid>
                <Grid style={{ marginBottom: "16px" }}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Status do Grupo</FormLabel>
                    <FormControlLabel
                      control={checkboxControl()}
                      label={enabled ? "Ativo" : "Inativo"}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                onClick={submitForm}
                type="submit"
                variant="contained"
                color="primary"
                mt={4}
              >
                {buttonName}
              </Button>
            </form>
          </Paper>
        )}
      </CardContent>
    </Card>
  );
};
export default GroupsForm;
