import axios from "axios";
import { getCurrentUser } from "./AuthService";
const baseURL = process.env.REACT_APP_BACKOFFICE_API_URL;

const api = axios.create({
  baseURL,
  withCredentials: true,
});

api.interceptors.request.use(async (config) => {
  const user = await getCurrentUser();

  if (!user) return config;
  if (!config.headers) config.headers = {};

  const tokenResult = await user.getIdTokenResult();
  config.headers.Authorization = `Bearer ${tokenResult.token}`;

  return config;
});

export { api, baseURL };
