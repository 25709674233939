import { BannerService, GroupService } from "../../services";
import { format, parseISO } from "date-fns";
import { NavLink } from "react-router-dom";
import { Chip, ProtectedPage } from "../../components";
import { pt } from "date-fns/locale";
import { spacing } from "@material-ui/system";
import React from "react";
import styled from "styled-components";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@material-ui/icons";

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable() {
  const [data, setData] = React.useState<any>();
  const [deleteId, setDeleteId] = React.useState<any>();
  const [groups, setGroups] = React.useState("");
  const [name, setName] = React.useState<any>();
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRemove = async () => {
    await BannerService.deleteById(deleteId);
    handleClose();
    const resList = await BannerService.list(page);
    setData(resList.data);
  };

  const handleRemoveAction = (id: any, name: any) => {
    setOpen(true);
    setDeleteId(id);
    setName(name);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteId(null);
  };

  React.useEffect(() => {
    (async () => {
      const resList = await BannerService.list(page);
      setData(resList.data);
    })();
  }, [page]);

  return (
    <div>
      <Paper>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle id="alert-dialog-title">{`Deseja excluir ${name}?`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Tem certeza que deseja excluir?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRemove} color="primary">
              Sim
            </Button>
            <Button onClick={handleClose} color="primary" autoFocus={true}>
              Não
            </Button>
          </DialogActions>
        </Dialog>

        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableCell align="left">Preview</TableCell>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Grupo</TableCell>
              <TableCell align="center">Ordem</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Criação / Atualização</TableCell>
              <TableCell align="right">Ações</TableCell>
            </TableHead>
            <TableBody>
              {data?.rows?.map((row: any, index: number) => {
                return (
                  <TableRow
                    hover={true}
                    key={`${row.id}-${index}`}
                    tabIndex={1}
                  >
                    <TableCell component="th" scope="row">
                      <div
                        style={{
                          alignItems: "left",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <img
                          alt="banners image"
                          height="58px"
                          src={row?.image?.location}
                          width="58px"
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      <Typography>
                        {row.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      <Typography>
                        {row.Group.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      <Typography>
                        {row.order}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.enabled && <Chip color='#4aff4a' message='Ativo' />}
                      {!row.enabled && <Chip color='#c9c9c9' message='Inativo' />}
                    </TableCell>
                    <TableCell align="center">
                      <Typography>
                        {format(parseISO(row.createdAt), "Pp", {
                          locale: pt,
                        })}
                        <br />
                        {format(parseISO(row.updatedAt), "Pp", {
                          locale: pt,
                        })}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        aria-label="edit"
                        component={NavLink}
                        exact={true}
                        to={`banners/${row.id}/edit`}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="details"
                        component={NavLink}
                        exact={true}
                        to={`banners/${row.id}/banners-detail`}
                      >
                        <RemoveRedEyeIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={(id) => handleRemoveAction(row.id, row.name)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[6]}
          component="div"
          count={data?.count}
          rowsPerPage={6}
          page={page}
          onChangePage={handleChangePage}
        />
      </Paper>
    </div>
  );
}

export const BannersIndexPage = () => {
  return (
    <ProtectedPage
      title="Banners"
      breadcrumbs={[{ name: 'Banners', to: '/banners' }]}
      action={
        <Button
          color="primary"
          component={NavLink}
          exact={true}
          startIcon={<AddIcon />}
          to="/banners/new"
          variant="contained"
        >
          Cadastrar Banner
        </Button>
      }
    >
      <Grid container={true} spacing={6}>
        <Grid item={true} xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </ProtectedPage>
  );
};
