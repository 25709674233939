import { NavLink } from "react-router-dom";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { NotificationsService } from "../../services";

import {
  Button,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";

import { ProtectedPage } from "../../components";
import { spacing } from "@material-ui/system";
import { Add } from "@material-ui/icons";
import { format, isPast } from "date-fns";
import { Notification, TargetGroup } from "../../services/NotificationsService";

import {
  RemoveRedEye as RemoveRedEyeIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import { DeleteConfirmationModal } from "../../components/Modal/DeleteConfirmationModal";
import { Alert } from "@material-ui/lab";
import { useAlert } from "../../hooks/useAlert";
import { BaseList } from "../../types/api";

type TranslatedTargetGroup = 'Profissional PJ' | 'Profissional PF' | 'Aluno';
type Status = 'Agendado' | 'Enviado'
type NotificationItem = Omit<Notification, 'targetGroup'> & {
  status: Status;
  targetGroup: TranslatedTargetGroup;
};

interface NotificationTable extends Omit<BaseList<Notification>, 'rows'> {
  rows: Array<NotificationItem>,
}

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable() {
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState<NotificationTable>();
  const [itemToRemove, setItemToRemove] = useState<NotificationItem>();

  const { alertOptions, showAlert } = useAlert();

  const targetGroupTranslation = useMemo<Record<TargetGroup, TranslatedTargetGroup>>(() => ({
    company: 'Profissional PJ',
    trainer: 'Profissional PF',
    regular: 'Aluno',
  }), []);

  const formatValues = (dataRow: Notification): NotificationItem => {
    const descriptionMaxCharacters = 25;

    return {
      ...dataRow,
      status: (isPast(new Date(dataRow.scheduledAt)) ? 'Enviado' : 'Agendado') as Status,
      targetGroup: targetGroupTranslation[dataRow.targetGroup],
      scheduledAt: format(new Date(dataRow.scheduledAt), 'dd/MM/yyyy'),
      description: dataRow.description?.length > descriptionMaxCharacters
        ? `${dataRow.description.substring(0, descriptionMaxCharacters)}...`
        : dataRow.description,
    }
  }

  const listNotifications = (): void => {
    NotificationsService.list(page, 6)
      .then((response) => {
        const rows = response.data.rows.map((item) => (formatValues(item)));

        setData({ ...response.data, rows })
      })
      .catch((error) => {
        console.error(error);
        showAlert('Ocorreu um erro ao buscar as notificações', 'error');
      })
  }

  React.useEffect(() => {
    listNotifications();
  }, [page, targetGroupTranslation]);

  const tableColumns = [
    { label: 'ID', key: 'id' },
    { label: 'Título', key: 'title' },
    { label: 'Mensagem abreviada', key: 'description' },
    { label: 'Público alvo', key: 'targetGroup' },
    { label: 'Status', key: 'status' },
    { label: 'Data', key: 'scheduledAt' },
  ];

  const handleDeleteModalClose = (remove: boolean) => {
    if (remove && itemToRemove) {
      NotificationsService.deleteById(itemToRemove.id)
        .then(() => {
          showAlert('Removido com sucesso!', 'success');
          listNotifications();
        })
        .catch(() => showAlert('Houve um erro ao remover a notificação!', 'error'));
    }

    setItemToRemove(undefined);
  }

  return (
    <div>
      <Paper>
        <DeleteConfirmationModal
          isOpen={!!itemToRemove}
          onClose={handleDeleteModalClose}
          title={itemToRemove?.title}
        />

        {alertOptions?.show && (
          <Alert severity={alertOptions.type}>
            {alertOptions?.message}
          </Alert>
        )}

        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              {tableColumns.map((column) => (
                <TableCell key={column.key}> {column.label} </TableCell>
              ))}
              <TableCell> Ações </TableCell>
            </TableHead>

            <TableBody>
              {data?.rows?.map((row: any) => {
                return (
                  <TableRow
                    hover={true}
                    key={row.id}
                    tabIndex={-1}
                  >
                    {tableColumns.map((column) => (
                      <TableCell key={column.key} component="th" scope="row">
                        <Typography>
                          {row[column.key]}
                        </Typography>
                      </TableCell>
                    ))}

                    <TableCell align="right">
                      <div style={{ display: 'flex' }}>
                        <IconButton
                          aria-label="details"
                          component={NavLink}
                          to={`/notifications/${row.id}/notification-detail`}
                          title="Visualizar Notificação"
                        >
                          <RemoveRedEyeIcon />
                        </IconButton>
                        <IconButton
                          aria-label="edit"
                          component={NavLink}
                          to={`notifications/${row.id}/edit`}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => setItemToRemove(row)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[6]}
          component="div"
          count={data?.count || 0}
          rowsPerPage={6}
          page={page}
          onChangePage={(_, newPage) => setPage(newPage)}
        />
      </Paper>
    </div>
  );
}

export const NotificationsIndexPage = () => {
  return (

    <ProtectedPage
      title="Notificações"
      breadcrumbs={[{
        name: 'Notificações', to: '/notifications'
      }]}
      action={
        <Button
          color="primary"
          component={NavLink}
          exact={true}
          startIcon={<Add />}
          to="/notifications/new"
          variant="contained"
        >
          Criar Notificações
        </Button>
      }
    >
      <Grid container={true} spacing={6}>
        <Grid item={true} xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </ProtectedPage>
  );
};