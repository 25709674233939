import React from "react";

const TrainerForm: React.FC<any> = (props: any) => {

  return (
    <div />
  );
};

export default TrainerForm;
