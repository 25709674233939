import styled from "styled-components";

import {
  Card as MuiCard,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";

import { ProtectedPage } from "../../components";
import { spacing } from "@material-ui/system";

const Card = styled(MuiCard)(spacing);

function DetailPage() {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h4" gutterBottom={true}>
          Informações detalhadas
        </Typography>

        <Grid container={true} spacing={8}>
          <Grid item={true} md={9}>
            <Grid container={true} spacing={8} style={{ marginBottom: '16px' }}>
              <Grid item={true} md={4}>
                <Typography variant="h6">
                  Nome
                </Typography>
                <Typography variant="body1">
                  Academia Move
                </Typography>
              </Grid>
              <Grid item={true} md={4}>
                <Typography variant="h6">
                  Grupo
                </Typography>
                <Typography variant="body1">
                  Presencial
                </Typography>
              </Grid>
              <Grid item={true} md={4}>
                <Typography variant="h6">
                  Status
                </Typography>
                <Typography variant="body1">
                  Ativo
                </Typography>
              </Grid>
            </Grid>
            <Grid container={true} spacing={8} style={{ marginBottom: '16px' }}>
              <Grid item={true} md={4}>
                <Typography variant="h6">
                  URL
                </Typography>
                <Typography variant="body1">
                  URL
                </Typography>
              </Grid>
              <Grid item={true} md={4}>
                <Typography variant="h6">
                  ID
                </Typography>
                <Typography variant="body1">
                  ID
                </Typography>
              </Grid>
              <Grid item={true} md={4}>
                <Typography variant="h6">
                  Cor
                </Typography>
                <Typography variant="body1">
                  #c7b96f
                </Typography>
              </Grid>
            </Grid>
            <Grid container={true} spacing={8}>
              <Grid item={true} md={4}>
                <Typography variant="h6">
                  Criação
                </Typography>
                <Typography variant="body1">
                  Criação
                </Typography>
              </Grid>
              <Grid item={true} md={4}>
                <Typography variant="h6">
                  Atualização
                </Typography>
                <Typography variant="body1">
                  Atualização
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export const PaymentsDetailPage = () => {
  return (
    <ProtectedPage
      title="Banner detalhado"
      breadcrumbs={[
        { name: 'Banner detalhado', to: '/banners/banners-detail' }
      ]}
    >
      <Grid container={true} spacing={6}>
        <Grid item={true} xs={12}>
          <DetailPage />
        </Grid>
      </Grid>
    </ProtectedPage>
  );
};
