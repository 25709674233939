import { format, isFuture } from "date-fns";
import { ProtectedPage } from "../../components";
import { spacing } from "@material-ui/system";
import { useParams } from "react-router-dom";
import React from "react";
import styled from "styled-components";

import {
    Card as MuiCard,
    CardContent,
    Grid,
    GridSize,
    Paper as MuiPaper,
    Typography,
} from "@material-ui/core";
import { Notification, getById, TargetGroup } from "../../services/NotificationsService";
import { useAlert } from "../../hooks/useAlert";
import { CustomAlert } from "../../components/CustomAlert";

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);


interface FormatedValues extends Omit<Notification, 'targetGroup'> {
    status: 'Agendado' | 'Enviado';
    targetGroup: 'Profissional PJ' | 'Profissional PF' | 'Aluno';
}

const translatedTargetGroup: Record<TargetGroup, FormatedValues['targetGroup']> = {
    company: 'Profissional PJ',
    trainer: 'Profissional PF',
    regular: 'Aluno',
};

function DetailPage() {
    const { id } = useParams<{ id: string }>();
    const [data, setData] = React.useState<FormatedValues>();

    const { alertOptions, showAlert } = useAlert();

    const formatValues = (dataRow: Notification): FormatedValues => {
        return {
            ...dataRow,
            id,
            status: isFuture(new Date(dataRow.scheduledAt)) ? 'Agendado' : 'Enviado',
            scheduledAt: format(new Date(dataRow.scheduledAt), 'dd/MM/yyyy HH:hh'),
            targetGroup: translatedTargetGroup[dataRow.targetGroup],
        };
    }

    React.useEffect(() => {
        getById(id)
            .then((response) => setData(formatValues(response.data)))
            .catch((error) => {
                console.error(error);
                showAlert('Ocorreu um erro ao buscar a notificação', 'error');
            })
    }, [id]);

    const gridValues: { label: string; key: keyof FormatedValues; md?: GridSize }[] = [
        { label: 'ID', key: 'id', md: 6 },
        { label: 'Título', key: 'title', md: 6 },
        { label: 'Mensagem', key: 'description', md: 12 },
        { label: 'Público Alvo', key: 'targetGroup' },
        { label: 'Status', key: 'status' },
        { label: 'Data / Hora', key: 'scheduledAt' },
    ];

    return (
        <div>
            <CustomAlert alertOptions={alertOptions} />

            <Paper>
                <Card mb={6}>
                    <CardContent>
                        <Typography variant="h4" gutterBottom style={{ marginBottom: "16px" }}>
                            Visualizar Notificação
                        </Typography>

                        <Grid container spacing={8}>
                            {gridValues.map((gridItem) => (
                                <Grid key={gridItem.key} item md={gridItem.md || 4}>
                                    <Typography variant="h6" style={{ marginBottom: '8px' }}>
                                        {gridItem.label}
                                    </Typography>
                                    <Typography variant="body1">
                                        {data?.[gridItem.key]}
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    </CardContent>
                </Card>
            </Paper>
        </div>
    )
}

export const NotificationsDetailPage = () => {
    return (
        <ProtectedPage
            title="Visualizar Notificação"
            breadcrumbs={[
                { name: 'Notificações', to: '/notifications' },
                { name: 'Visualizar Notificação', to: '/notifications/:id/notification-detail' }
            ]}
        >
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <DetailPage />
                </Grid>
            </Grid>
        </ProtectedPage>
    );
};
