import { DatePicker } from "@material-ui/pickers";
import { Chip, ProtectedPage } from "../../components";
import { format, parseISO } from "date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { PaymentsService } from "../../services";
import { pt } from "date-fns/locale";
import { spacing } from "@material-ui/system";
import { useEffect, useState, MouseEvent } from "react";
import styled from "styled-components";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";

const Paper = styled(MuiPaper)(spacing);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginRight: theme.spacing(4),
      marginTop: theme.spacing(4),
    },
    textField: {
      margin: theme.spacing(1),
      minWidth: 140,
    },
  })
);

interface SearchOptions {
  page: number;
  startDate: MaterialUiPickersDate;
  endDate: MaterialUiPickersDate;
}

function EnhancedTable() {
  const [data, setData] = useState<any>();
  const classes = useStyles();
  const [searchOptions, setSearchOptions] = useState<SearchOptions>({
    page: 0,
    startDate: null,
    endDate: null,
  });

  const { page, startDate, endDate } = searchOptions;

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setSearchOptions({ ...searchOptions, page: newPage });
  };

  useEffect(() => {
    (async () => {
      const resList = await PaymentsService.list(page, 6, startDate, endDate);
      setData(resList.data);
    })();
  }, [endDate, page, startDate]);

  const onFilterByDateClearChange = () => {
    setSearchOptions({
      startDate: null,
      endDate: null,
      page: 0,
    });
  }

  return (
    <div>
      <Grid container={true} spacing={8} justify="flex-end">
        <Grid item={true}>
          <DatePicker
            label='Inicío do período'
            onChange={(date) => setSearchOptions({ ...searchOptions, startDate: date, page: 0 })}
            value={startDate}
          />
        </Grid>
        <Grid item={true}>
          <DatePicker
            label='Fim do período'
            onChange={(date) => setSearchOptions({ ...searchOptions, endDate: date, page: 0 })}
            value={endDate}
          />
        </Grid>

        <Button className={classes.button} onClick={onFilterByDateClearChange}>
          Limpar Filtro
        </Button>
      </Grid>

      <Grid container={true} spacing={8}>
        <Grid item={true} xs={12}>
          <Paper>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableCell>Aluno</TableCell>
                  <TableCell>Fornecedor</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Criação / Atualização</TableCell>
                </TableHead>
                <TableBody>
                  {data?.rows?.map((row: any, index: number) => {
                    return (
                      <TableRow
                        hover={true}
                        key={`${row.id}-${index}`}
                        tabIndex={1}
                      >
                        <TableCell component="th" scope="row">
                          <Typography>
                            @{row.buyerProfile && row.buyerProfile.username}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography>
                            @{row.sellerProfile && row.sellerProfile.username}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography>
                            {row.lessonPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography>
                            {row.status === 'refunded' && <Chip color='#ff7070' message='Estornado' />}
                            {row.status === 'pending' && <Chip color='#c9c9c9' message='Em análise' />}
                            {row.status === 'paid' && <Chip color='#4aff4a' message='Aprovado' />}
                            {row.status === 'expired' && <Chip color='#ffc000' message='Vencido' />}
                            {row.status === 'canceled' && <Chip color='#ff7070' message='Cancelada' />}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {format(parseISO(row.createdAt), "Pp", {
                              locale: pt,
                            })}
                            <br />
                            {format(parseISO(row.updatedAt), "Pp", {
                              locale: pt,
                            })}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[6]}
              component="div"
              count={data?.count}
              rowsPerPage={6}
              page={page}
              onChangePage={handleChangePage}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export const PaymentsIndexPage = () => {
  return (

    <ProtectedPage
      title="Pagamentos"
      breadcrumbs={[{
        name: 'Pagamentos', to: '/payments'
      }]}
    >
      <Grid container={true} spacing={6}>
        <Grid item={true} xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </ProtectedPage>
  );
};
