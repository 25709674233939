import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Grid,
  Paper as MuiPaper,
  Typography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import { spacing, SpacingProps } from "@material-ui/system";
import { Formik } from "formik";
import React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { FFTextField, ProtectedPage } from "../../components";
import { UserAccountService } from "../../services";

const Alert = styled(MuiAlert)(spacing);

const Button = styled(MuiButton)<ButtonPropstype>(spacing);

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

interface ButtonPropstype extends SpacingProps {
  component?: string;
}

const initialValues = {
  confirmPassword: "",
  password: "",
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Deve ter ao menos 6 carateres")
    .max(64, "Pode ter no máximo 64 carateres")
    .required("Senha necessária"),
  confirmPassword: Yup.string().when("password", {
    is: (val: any) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "As senhas devem ser iguais"
    ),
  }),
});

function Form() {
  const handleSubmit = async (
    values: any,
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      await UserAccountService.changePassword(values);
      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ ...error.response.data, submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <Card mb={6}>
          <CardContent>
            <Typography variant="h4" gutterBottom={true}>
              Alterar Senha
            </Typography>

            {status && status.sent && (
              <Alert severity="success" my={3}>
                Senha alterada!
              </Alert>
            )}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <Paper>
                <form onSubmit={handleSubmit}>
                  <Grid item={true} md={6} style={{ marginBottom: "16px" }}>
                    <FFTextField
                      name="password"
                      label="Nova senha"
                      value={values.password}
                      error={Boolean(touched.password && errors.password)}
                      fullWidth={true}
                      helperText={touched.password && errors.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      type="password"
                      my={2}
                    />
                  </Grid>
                  <Grid item={true} md={6} style={{ marginBottom: "16px" }}>
                    <FFTextField
                      name="confirmPassword"
                      label="Confirme sua senha"
                      value={values.confirmPassword}
                      error={Boolean(
                        touched.confirmPassword && errors.confirmPassword
                      )}
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                      fullWidth={true}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      type="password"
                      my={2}
                    />
                  </Grid>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={4}
                  >
                    Salvar
                  </Button>
                </form>
              </Paper>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

export const ChangePasswordPage = () => {
  return (
    <ProtectedPage
      title="Minha Conta"
      breadcrumbs={[{ name: "Alterar Senha", to: "/change-password" }]}
    >
      <Form />
    </ProtectedPage>
  );
};
