import { api } from "./BackendService";

export const edit = (id: string, amount: number) =>
  api.put(`/buyer-balances/${id}`, { amount });
export const list = (
  page: number,
  pageSize: number,
  document?: string,
  name?: string,
  id?: string,
  phoneNumber?: string
) =>
  api.get("/buyer-balances", {
    params: { page, pageSize, document, name, id, phoneNumber },
  });
