import { useParams } from "react-router-dom";

import React from "react";
import { useHistory } from "react-router-dom";

import { ProtectedPage } from "../../components";

import { WithdrawsService } from "../../services";
import { Box, Button, CircularProgress, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

export const WithdrawsEditFormPage = () => {
  const [data, setData] = React.useState<any>([]);
  const [status, setStatus] = React.useState({ sent: false });
  const [isSubmitting, setSubmitting] = React.useState(false);
  const { id } = useParams<{ id: any }>();
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const history = useHistory();

  React.useEffect(() => {
    (async () => {
      const resList = await WithdrawsService.getById(id);
      setData(resList.data);
    })();
  }, []);

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      await WithdrawsService.updateInvoiceLink(id, data);
      setStatus({ sent: true });
      setSubmitting(false);
      history.goBack();
    } catch (err: any) {
      if (err?.response?.status === 400) {
        setErrors(err?.response?.data);

      }
      setStatus({ sent: false });
      setSubmitting(false);
    }
  };

  return (
    <ProtectedPage
      title="Editar Saque"
      breadcrumbs={[
        { name: "Saques", to: "/withdraws" },
        { name: "Editar Saque", to: "/withdraws/:id/edit" },
      ]}
    >
      {status && status.sent && (
        <Alert style={{ marginBottom: 16 }} severity="success">
          Alteração Realizada!
        </Alert>
      )}

      {isSubmitting ? (
        <Box display="flex" justifyContent="center" my={6}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <span>Nota Fiscal: </span>
          <TextField onChange={(e) => setData(e.target.value)}> </TextField>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Salvar Alterações 
          </Button>
        </>
      )}
    </ProtectedPage>
  );
};
