import { BannerService } from "../../services";
import { BannersForm, ProtectedPage } from "../../components";
import { useParams } from "react-router-dom";
import React from "react";

export const BannersEditFormPage = () => {
  const [data, setData] = React.useState([]);
  const { id } = useParams<{ id: any }>();

  React.useEffect(() => {
    (async () => {
      const resList = await BannerService.getById(id);
      setData(resList.data);
    })();
  }, []);

  const handleSubmit = async (form: any) => {
    await BannerService.edit(id, form);
  };

  return (
    <ProtectedPage
      title="Editar Banner"
      breadcrumbs={[
        { name: 'Banners', to: '/banners' },
        { name: 'Editar Banner', to: '/banners/:id/edit' }
      ]}
    >
      <BannersForm
        data={data}
        buttonName="Atualizar"
        formTitle="Editar Banner"
        handleSubmit={handleSubmit}
        resetFields={false}
        successMessage="Banner Atualizado com Sucesso!"
      />
    </ProtectedPage>
  );
};
