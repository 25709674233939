import { AdminService } from "../../services";
import { format, parseISO } from "date-fns";
import { NavLink } from "react-router-dom";
import { Chip, ProtectedPage } from "../../components";
import { pt } from "date-fns/locale";
import { spacing } from "@material-ui/system";
import React from "react";
import styled from "styled-components";

import {
  Avatar as MuiAvatar,
  Box as MuiBox,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@material-ui/icons";

const Paper = styled(MuiPaper)(spacing);

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
  margin-right: 16px;
`;

const AdminUser = styled.div`
  align-items: center;
  display: flex;
`;

function EnhancedTable() {
  const [data, setData] = React.useState<any>();
  const [deleteId, setDeleteId] = React.useState<any>();
  const [name, setName] = React.useState<any>();
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRemove = async () => {
    await AdminService.deleteById(deleteId);
    handleClose();
    const resList = await AdminService.list(page, 6);
    setData(resList.data);
  };

  const handleRemoveAction = (id: any, name: any) => {
    setOpen(true);
    setDeleteId(id);
    setName(name);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteId(null);
  };

  React.useEffect(() => {
    (async () => {
      const resList = await AdminService.list(page, 6);
      setData(resList.data);
    })();
  }, [page]);

  return (
    <div>
      <Paper>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle id="alert-dialog-title">{`Deseja excluir ${name}?`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Tem certeza que deseja excluir?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRemove} color="primary">
              Sim
            </Button>
            <Button onClick={handleClose} color="primary" autoFocus={true}>
              Não
            </Button>
          </DialogActions>
        </Dialog>

        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Nome / E-mail</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Criação / Atualização</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.rows?.map((row: any, index: number) => {
                return (
                  <TableRow
                    hover={true}
                    key={`${row.id}-${index}`}
                    tabIndex={-1}
                  >
                    <TableCell component="th" scope="row">
                      <AdminUser>
                        <Avatar />
                        <Typography>
                          <strong>{row.name} {row.lastname}</strong>
                          <br />
                          {row.email}
                        </Typography>
                      </AdminUser>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.enabled && <Chip color='#4aff4a' message='Ativo' />}
                      {!row.enabled && <Chip color='#c9c9c9' message='Inativo' />}
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {format(parseISO(row.createdAt), "Pp", {
                          locale: pt,
                        })}
                        <br />
                        {format(parseISO(row.updatedAt), "Pp", {
                          locale: pt,
                        })}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        aria-label="edit"
                        component={NavLink}
                        exact={true}
                        to={`/admin-users/${row.id}/edit`}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="details"
                        component={NavLink}
                        exact={true}
                        to={`/admin-users/${row.id}/admin-detail`}
                      >
                        <RemoveRedEyeIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={(id) => handleRemoveAction(row.id, row.name)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[6]}
          component="div"
          count={data?.count}
          rowsPerPage={6}
          page={page}
          onChangePage={handleChangePage}
        />
      </Paper>
    </div>
  );
}

export const AdminIndexPage = () => {
  return (
    <ProtectedPage
      title="Administradores"
      breadcrumbs={[
        { name: 'Administradores', to: '/admin-users' }
      ]}
      action={
        <Button
          color="primary"
          component={NavLink}
          exact={true}
          startIcon={<AddIcon />}
          to="/admin-users/new"
          variant="contained"
        >
          Cadastrar Administrador
        </Button>
      }
    >
      <Grid container={true} spacing={6}>
        <Grid item={true} xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </ProtectedPage>
  );
};
