import { CloudUpload as MuiCloudUpload } from "@material-ui/icons";
import { FFTextField } from "./FFTextField";
import { spacing, SpacingProps } from "@material-ui/system";
import BankAccountForm from "./BankAccountForm";
import CompanyForm from "./CompanyForm";
import React, { useEffect } from "react";
import styled from "styled-components";
import TrainerForm from "./TrainerForm";
import UsersForm from "./UsersForm";

import {
  Avatar,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  FormControl as MuiFormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";

const Card = styled(MuiCard)(spacing);

const FormControl = styled(MuiFormControl)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)}px;
`;

interface ButtonPropstype extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonPropstype>(spacing);

interface ProfileEditFormProps {
  data?: any;
  onDataChange: any;
  errors?: any;
  successMessage: string;
}

const ProfileEditForm: React.FC<ProfileEditFormProps> = ({
  errors,
  data,
  onDataChange,
  successMessage,
}) => {
  const [image, setImage] = React.useState<any>({});
  const [imageBlob, setImageBlob] = React.useState<any>({});

  useEffect(() => {
    setImage(data?.image);
    setImageBlob(data?.image?.location);
  }, [data]);

  const imageValidation = () => {
    if (imageBlob === "" || image === "")
      return (
        <Typography variant="caption" gutterBottom={true} color="error">
          Imagem não pode ser vazia
        </Typography>
      );
  };

  const handleChangeImage = (event: any) => {
    const selectedImage = event.target.files;

    if (selectedImage.length) {
      const file = selectedImage[0];
      const blob = URL.createObjectURL(file);
      setImage(blob);

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const img = {
          name: file.name,
          type: file.type,
          data: reader.result,
        };

        onDataChange({ ...data, image: img.data });
      };
    }
  };

  const onCompanyDataChange = (companyData: any) => {
    const changedData = { ...data };
    changedData.company = companyData;
    onDataChange(changedData);
  };

  const onReturnCompanyForm = () => {
    return (
      <CompanyForm
        successMessage="Perfil Atualizado com Sucesso"
        data={data.company}
        errors={errors}
        onDataChange={onCompanyDataChange}
      />
    );
  };

  const onTrainerDataChange = (trainerData: any) => {
    const changedData = { ...data };
    changedData.trainer = trainerData;
    onDataChange(changedData);
  };

  const onReturnTrainerForm = () => {
    return (
      <TrainerForm
        successMessage="Perfil Atualizado com Sucesso"
        data={data.trainer}
        errors={errors}
        onDataChange={onTrainerDataChange}
      />
    );
  };

  const onBankAccountDataChange = (bankAccountData: any) => {
    const changedData = { ...data };
    changedData.bankAccount = bankAccountData;
    onDataChange(changedData);
  };

  const onReturnBankAccountForm = () => {
    return (
      <BankAccountForm
        successMessage="Conta bancária Atualizada com Sucesso"
        data={data?.bankAccount}
        errors={errors}
        onDataChange={onBankAccountDataChange}
      />
    );
  };

  return (
    <div>
      <Card mb={6}>
        <CardContent>
          <Grid container={true} spacing={6}>
            <Grid item={true} md={8}>
              <FFTextField
                name="username"
                label="Nome de usuário"
                value={data?.username || ""}
                fullWidth={true}
                variant="outlined"
                disabled={true}
                my={2}
                error={!!errors?.username}
                helperText={errors?.username}
              />

              <FormControl fullWidth={true} variant="outlined">
                <FFTextField
                  name="description"
                  label="Descrição"
                  value={data?.description || ""}
                  multiline={true}
                  rows={3}
                  rowsMax={4}
                  fullWidth={true}
                  onChange={(event) =>
                    onDataChange({ ...data, description: event.target.value })
                  }
                  variant="outlined"
                  my={2}
                />
              </FormControl>
            </Grid>
            <Grid item={true} md={4}>
              <CenteredContent>
                <BigAvatar alt={data?.username} src={imageBlob || image} />
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  type="file"
                  multiple={true}
                  onChange={handleChangeImage}
                />
                <label htmlFor="raised-button-file">
                  <Button variant="contained" color="primary" component="span">
                    <CloudUpload mr={2} /> Upload
                  </Button>

                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom={true}
                  >
                    For best results, use an image at least 128px by 128px in
                    .jpg format
                  </Typography>
                  {imageValidation()}
                </label>
              </CenteredContent>
            </Grid>
          </Grid>

          <Grid item={true} md={2}>
            <FormControl>
              <FormLabel component="legend">Status</FormLabel>
              <RadioGroup
                aria-label="status"
                name="status"
                value={data?.status || ""}
                onChange={(event) =>
                  onDataChange({ ...data, status: event.target.value })
                }
              >
                <FormControlLabel
                  value="active"
                  control={<Radio color="primary" />}
                  label="Ativo"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="inactive"
                  control={<Radio color="primary" />}
                  label="Inativo"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="blocked"
                  control={<Radio color="primary" />}
                  label="Bloqueado"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </CardContent>
      </Card>

      {data?.users?.map((user: any, index: number) => {
        return (
          <UsersForm
            key={user?.username}
            data={user}
            errors={errors}
            onDataChange={(userData: any) => {
              const changedData = { ...data };
              changedData.users[index] = userData;
              onDataChange(changedData);
            }}
            successMessage="Administrador Atualizado com Sucesso"
          />
        );
      })}

      {data?.trainer && onReturnBankAccountForm()}

      {data?.company && onReturnBankAccountForm()}

      {data?.company && onReturnCompanyForm()}

      {data?.trainer && onReturnTrainerForm()}
    </div>
  );
};

export default ProfileEditForm;
