import { FFTextField } from "./FFTextField";
import { spacing } from "@material-ui/system";
import React from "react";
import styled from "styled-components";

import {
  Card as MuiCard,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";

const Card = styled(MuiCard)(spacing);

const CompanyForm: React.FC<any> = (props: any) => {
  return (
    props?.data && (
      <div>
        <Card mb={6}>
          <CardContent>
            <Typography variant="h4" gutterBottom={true}>
              Informações da Empresa
            </Typography>
            <Grid container={true} spacing={6}>
              <Grid item={true} xs={6}>
                <FFTextField
                  name="document"
                  label="CNPJ"
                  value={props?.data?.document || ""}
                  fullWidth={true}
                  onChange={(event) =>
                    props.onDataChange({
                      ...props.data,
                      document: event.target.value,
                    })
                  }
                  type="email"
                  variant="outlined"
                  my={2}
                  error={!!props.errors?.document}
                  helperText={props.errors?.document}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    )
  );
};

export default CompanyForm;
