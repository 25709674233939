import { NavLink } from "react-router-dom";
import { pt } from "date-fns/locale";
import React from "react";
import styled from "styled-components";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  createStyles,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@material-ui/icons";

import { format, parseISO } from "date-fns";
import { WithdrawsService } from "../../services";
import { Chip, ProtectedPage } from "../../components";
import { spacing } from "@material-ui/system";

const Paper = styled(MuiPaper)(spacing);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      margin: theme.spacing(1),
      minWidth: 140,
    },
  })
);

function EnhancedTable() {
  const [data, setData] = React.useState<any>();
  const [page, setPage] = React.useState(0);
  useStyles();

  React.useEffect(() => {
    (async () => {
      const resList = await WithdrawsService.list(page, 6);
      setData(resList.data);
    })();
  }, [page]);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  return (
    <div>
      <Grid container={true} spacing={8} justify="flex-end">
        <Grid item={true}></Grid>
        <Grid item={true}></Grid>
        <Grid item={true}></Grid>
      </Grid>

      <Grid container={true} spacing={8}>
        <Grid item={true} xs={12}>
          <Paper>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableCell>Id</TableCell>
                  <TableCell>Orfertante</TableCell>
                  <TableCell>CPF/CNPJ</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Nota-Fiscal</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableHead>
                <TableBody>
                  {data?.rows?.map((row: any, index: number) => {
                    return (
                      <TableRow
                        hover={true}
                        key={`${row.id}-${index}`}
                        tabIndex={-1}
                      >
                        <TableCell component="th" scope="row">
                          <Typography>
                            <strong>{row?.id}</strong>
                            <br />
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography>{row?.name}</Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography>
                            {row?.role === "company" ? "cnpj" : "cpf"}{" "}
                            {row?.document}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography>{row?.status}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{row?.invoiceLink}</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="details"
                            component={NavLink}
                            exact={true}
                            to={`withdraws/${row.id}/${row.invoiceLink}/detail`}
                          >
                            <RemoveRedEyeIcon />
                          </IconButton>
                          {row?.status === "accepted" && (
                            <IconButton
                              component={NavLink}
                              exact={true}
                              to={`withdraws/${row.id}/edit`}
                              aria-label="editar"
                            >
                              <EditIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[6]}
              component="div"
              count={data?.count}
              rowsPerPage={6}
              page={page}
              onChangePage={handleChangePage}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export const WithdrawsIndexPage = () => {
  return (
    <ProtectedPage
      title="Saques"
      breadcrumbs={[
        {
          name: "Saques",
          to: "/withdraws",
        },
      ]}
    >
      <Grid container={true} spacing={6}>
        <Grid item={true} xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </ProtectedPage>
  );
};
