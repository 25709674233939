import { ProtectedPage } from "../../components";

import NotificationsForm from "../../components/NotificationsForm";

export const NotificationsFormPage = () => {
    return (
        <ProtectedPage
            title="Criar Notificações"
            breadcrumbs={[
                { name: "Notificações", to: "/notifications" },
                { name: "Criar Notificações", to: "/notifications/new" },
            ]}
        >
            <NotificationsForm />
        </ProtectedPage>
    );
};
