import { initializeApp } from "firebase/app";
import { getAuth, User, signInWithEmailAndPassword } from "firebase/auth"
import { SignInType } from "../types/auth";

const config = {
  // apiKey: "AIzaSyAWfSGgXSgg_Fr9sMzgzn4RyyZ8vJWTtHg",
  // authDomain: "fitfit-backoffice-stage.firebaseapp.com",
  // projectId: "fitfit-backoffice-stage",
  // storageBucket: "fitfit-backoffice-stage.appspot.com",
  // messagingSenderId: "298017728245",
  // appId: "1:298017728245:web:2fe74586a4678fab19c7bc",
  // measurementId: "G-9104LZL9Y5"
  apiKey: "AIzaSyBKT7bMzHncUiBi6EFKOfUtAFu8mcuSb5A",
  authDomain: "fitfit-backoffice.firebaseapp.com",
  projectId: "fitfit-backoffice",
  storageBucket: "fitfit-backoffice.appspot.com",
  messagingSenderId: "1025816877557",
  appId: "1:1025816877557:web:066da6422109357a88a8bc",
  measurementId: "G-6GWVB9D38Y"
};

const app = initializeApp(config);

export type AuthUser = User

const auth = getAuth();

export const getCurrentUser = () : Promise<AuthUser | null> =>
  new Promise<AuthUser | null>((resolve) => {
    auth.onAuthStateChanged(resolve);
  });

export const signIn = (credentials: SignInType) => {
  return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(auth, credentials.email, credentials.password)
      .then(resolve)
      .catch((err) => {
        if (["auth/user-not-found", "auth/wrong-password"].includes(err.code)) {
          reject({ message: "Email ou senha inválidos" });
        } else {
          reject({ message: "Ocorreu um erro inexperado" });
        }
      });
  });
};

export const signOut = () => auth.signOut();
