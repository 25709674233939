import { Alert } from "@material-ui/lab";
import { AlertOptions } from "../hooks/useAlert"

interface AlertProps {
    alertOptions?: AlertOptions,
}

export const CustomAlert: React.FC<AlertProps> = ({
    alertOptions,
}) => {
    return (
        <>
            {alertOptions?.show && (
                <Alert severity={alertOptions.type}>
                    {alertOptions?.message}
                </Alert>
            )}
        </>
    );
};
