import { format, parseISO } from "date-fns";
import { LessonsService } from "../../services";
import { ProtectedPage } from "../../components";
import { pt } from "date-fns/locale";
import { spacing } from "@material-ui/system";
import { useParams } from "react-router";
import React from "react";
import styled from "styled-components";

import {
  Card as MuiCard,
  CardContent,
  Grid,
  Paper as MuiPaper,
  Typography,
} from "@material-ui/core";

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

function DetailPage() {
  const [data, setData] = React.useState<any>([]);
  let formatedLessonPrice;
  const { id } = useParams<{ id: any }>();

  React.useEffect(() => {
    (async () => {
      const resList = await LessonsService.getById(id);
      setData(resList.data)
    })();
  }, []);

  const lessonImageItem = (row: any) => {
    return (
      <Grid item={true} md={4} key={row.id}>
        <div>
          <img src={row?.image?.location} style={{ width: '375px', borderRadius: '12px' }} />
        </div>
      </Grid>
    )
  }

  const lessonImagesSection = () => {
    return (
      <div>
        <Typography variant='h4' gutterBottom={true} style={{ marginBottom: "16px" }}>
          Imagens da Aula
        </Typography>

        <Grid container={true} spacing={8} style={{ marginBottom: '30px' }}>
          <Grid item={true} md={12}>
            <Grid container={true} spacing={8} style={{ marginBottom: '16px' }}>
              {data?.lessonImages?.map((row: any) => { return (lessonImageItem(row)) })}
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

  const lessonVideoItem = (row: any) => {
    return (
      <Grid item={true} md={4} key={row.id}>
        <div>
          <video>
            <source src={row.video} />
          </video>
        </div>
      </Grid>
    )
  }

  const lessonVideosSection = () => {
    return (
      <div>
        <Typography variant='h4' gutterBottom={true} style={{ marginBottom: "16px" }}>
          Videos da Aula
        </Typography>

        <Grid container={true} spacing={8} style={{ marginBottom: '30px' }}>
          <Grid item={true} md={12}>
            <Grid container={true} spacing={8} style={{ marginBottom: '16px' }}>
              {data?.lessonVideos?.map((row: any) => { return (lessonVideoItem(row)) })}
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

  const lessonScheduleItem = (row: any, lessonPrice: any) => {
    return (
      <div>
        <Grid container={true} spacing={8} style={{ marginBottom: '30px' }}>
          <Grid item={true} md={12} style={{ marginBottom: '30px' }}>
            <Grid container={true} spacing={8} style={{ marginBottom: '16px' }}>
              <Grid item={true} md={4}>
                <Typography variant='h6'>
                  Dia
                </Typography>
                <Typography variant='body1'>
                  {row?.weekday}
                </Typography>
              </Grid>
              <Grid item={true} md={4}>
                <Typography variant='h6'>
                  Horário
                </Typography>
                <Typography variant='body1'>
                  {row?.time}
                </Typography>
              </Grid>
              <Grid item={true} md={4}>
                <Typography variant='h6'>
                  Preço
                </Typography>
                <Typography variant='body1'>
                  {lessonPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </Typography>
              </Grid>
            </Grid>
            <Grid container={true} spacing={8} style={{ marginBottom: '16px' }}>
              <Grid item={true} md={4}>
                <Typography variant='h6'>
                  Duração
                </Typography>
                <Typography variant='body1'>
                  {row?.duration} min
                </Typography>
              </Grid>
              <Grid item={true} md={4}>
                <Typography variant='h6'>
                  Vagas
                </Typography>
                <Typography variant='body1'>
                  {row?.vacancies}
                </Typography>
              </Grid>
              <Grid item={true} md={4}>
                <Typography variant='h6'>
                  Status
                </Typography>
                <Typography variant='body1'>
                  {row?.enabled ? 'Ativo' : 'Inativo'}
                </Typography>
              </Grid>
            </Grid>
            <Grid container={true} spacing={8} style={{ marginBottom: '16px' }}>
              <Grid item={true} md={4}>
                <Typography variant='h6'>
                  Criação
                </Typography>
                <Typography variant='body1'>
                  {row?.createdAt && format(parseISO(row.createdAt), "Pp", {
                    locale: pt,
                  })}
                </Typography>
              </Grid>
              <Grid item={true} md={4}>
                <Typography variant='h6'>
                  Atualização
                </Typography>
                <Typography variant='body1'>
                  {row?.updatedAt && format(parseISO(row.updatedAt), "Pp", {
                    locale: pt,
                  })}
                </Typography>
              </Grid>
              <Grid item={true} md={4}>
                <Typography variant='h6'>
                  ID
                </Typography>
                <Typography variant='body1'>
                  {row?.id}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

  const lessonSchedulesSection = () => {
    return (
      <div>
        <Typography variant='h4' gutterBottom={true} style={{ marginBottom: "16px" }}>
          Dias e Horários
        </Typography>

        {data?.lessonSchedules?.map((row: any) => {
          return lessonScheduleItem(row, row?.price);
        })}
      </div>
    )
  }

  return (
    <div>
      <Paper>
        <Card mb={6}>
          <CardContent>
            <Typography variant="h4" gutterBottom={true}>
              Informações detalhadas
            </Typography>

            {data?.lessonImages?.length !== 0 && lessonImagesSection()}

            {data?.lessonVideos?.length !== 0 && lessonVideosSection()}

            <Typography variant='h4' gutterBottom={true} style={{ marginBottom: "16px" }}>
              Aula
            </Typography>

            <Grid container={true} spacing={8} style={{ marginBottom: '30px' }}>
              <Grid item={true} md={12}>
                <Grid container={true} spacing={8} style={{ marginBottom: '16px' }}>
                  <Grid item={true} md={4}>
                    <Typography variant="h6">
                      Nome
                    </Typography>
                    <Typography variant="body1">
                      {data?.name}
                    </Typography>
                  </Grid>
                  <Grid item={true} md={4}>
                    <Typography variant="h6">
                      Idade
                    </Typography>
                    <Typography variant="body1">
                      {data?.ageGroupStart} - {data?.ageGroupEnd} anos
                    </Typography>
                  </Grid>
                  <Grid item={true} md={4}>
                    <Typography variant="h6">
                      Status
                    </Typography>
                    <Typography variant="body1">
                      {data?.enabled === true ? 'Ativo' : 'Inativo'}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container={true} spacing={8} style={{ marginBottom: '16px' }}>
                  <Grid item={true} md={4}>
                    <Typography variant="h6">
                      Descrição
                    </Typography>
                    <Typography variant="body1">
                      {data?.description}
                    </Typography>
                  </Grid>
                  <Grid item={true} md={4}>
                    <Typography variant="h6">
                      Categoria
                    </Typography>
                    <Typography variant="body1">
                      {data?.category?.name}
                    </Typography>
                  </Grid>
                  <Grid item={true} md={4}>
                    <Typography variant="h6">
                      Fornecedor
                    </Typography>
                    <Typography variant="body1">
                      {data?.profile?.username}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container={true} spacing={8} style={{ marginBottom: '16px' }}>
                  <Grid item={true} md={4}>
                    <Typography variant="h6">
                      Criação
                    </Typography>
                    <Typography variant="body1">
                      {data?.createdAt && format(parseISO(data.createdAt), "Pp", {
                        locale: pt,
                      })}
                    </Typography>
                  </Grid>
                  <Grid item={true} md={4}>
                    <Typography variant="h6">
                      Atualização
                    </Typography>
                    <Typography variant="body1">
                      {data?.updatedAt && format(parseISO(data.updatedAt), "Pp", {
                        locale: pt,
                      })}
                    </Typography>
                  </Grid>
                  <Grid item={true} md={4}>
                    <Typography variant="h6">
                      ID
                    </Typography>
                    <Typography variant="body1">
                      {data?.id}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {data?.lessonSchedules?.length !== 0 && lessonSchedulesSection()}
          </CardContent>
        </Card>
      </Paper>
    </div>
  )
}

export const LessonsDetailPage = () => {
  return (
    <ProtectedPage
      title="Aula detalhada"
      breadcrumbs={[{ name: 'Aulas', to: '/lessons' }, { name: 'Aula detalhado', to: '/lessons/:id/lessons-detail' }]}
    >
      <Grid container={true} spacing={6}>
        <Grid item={true} xs={12}>
          <DetailPage />
        </Grid>
      </Grid>
    </ProtectedPage>
  );
};
