import { FFTextField } from "./FFTextField";
import { spacing } from "@material-ui/system";
import React from "react";
import styled from "styled-components";

import {
  Card as MuiCard,
  CardContent,
  FormControl as MuiFormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";

const Card = styled(MuiCard)(spacing);

const FormControl = styled(MuiFormControl)(spacing);

interface CrefEditFormProps {
  data?: any;
  onDataChange: any;
}

const CrefEditForm: React.FC<CrefEditFormProps> = ({
  data,
  onDataChange,
}) => {

  return (
    <div>
      <Card mb={6}>
        <CardContent>
          <Grid container={true} spacing={6}>
            <Grid item={true} md={8}>
              <FFTextField
                name="cref-number"
                label="Número do CREF"
                value={data?.trainer?.cref || ""}
                fullWidth={true}
                variant="outlined"
                disabled={true}
                my={2}
              />
            </Grid>
          </Grid>
          <Grid item={true} md={2}>
            <FormControl>
              <FormLabel component="legend">Status</FormLabel>
              <RadioGroup
                aria-label="status"
                name="status"
                value={data?.trainer?.crefVerificationStatus || ""}
                onChange={(event) =>
                  onDataChange({ ...data, trainer: { ...data?.trainer, crefVerificationStatus: event.target.value } })
                }
              >
                <FormControlLabel
                  value="approved"
                  control={<Radio color="primary" />}
                  label="Aprovar"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="rejected"
                  control={<Radio color="primary" />}
                  label="Rejeitar"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default CrefEditForm;
