import { api } from "./BackendService";

interface WithdrawUpdateBody {
  invoiceLink: null | string;
}

const update = (id: string, body: WithdrawUpdateBody) =>
  api.patch(`/withdraws/${id}`, body);

export const getById = (id: string) => api.get(`/withdraws/${id}`);
export const list = (page: number, pageSize: number) =>
  api.get("/withdraws", { params: { page: page, pageSize: pageSize } });
export const updateInvoiceLink = (id: string, invoiceLink: string) =>
  update(id, { invoiceLink });
export const getMissingInvoicesCount = (): Promise<{ data: number }> =>
  api.get("withdraws/invoices/missing");
export const generatePDF = (id: string) => api.get(`withdraws/${id}/generate-pdf`);
