import { NavLink, useParams } from "react-router-dom";

import React from "react";

import { ProtectedPage } from "../../components";

import { ProfilesService, CrefService } from "../../services";
import { Box, Button, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CrefEditForm from "../../components/CrefEditForm";
import { SpaceBar } from "@material-ui/icons";

export const EditCrefPage = () => {
    const [data, setData] = React.useState<any>([]);
    const [status, setStatus] = React.useState({ sent: false });
    const [isSubmitting, setSubmitting] = React.useState(false);
    const { id } = useParams<{ id: any }>();

    React.useEffect(() => {
        (async () => {
            const resList = await ProfilesService.getById(id);
            setData(resList.data);
        })();
    }, []);

    const handleSubmit = async () => {
        setSubmitting(true);
        try {
            await CrefService.edit({ trainerId: data.trainer.id, status: data.trainer.crefVerificationStatus });
            setStatus({ sent: true });
            setSubmitting(false);
        } catch (err: any) {
            setStatus({ sent: false });
            setSubmitting(false);
        }
    };

    return (
        <ProtectedPage
            title="Editar Status do Cref"
            breadcrumbs={[
                { name: "Perfis", to: "/profiles" },
                { name: "Editar Status do Cref", to: "/profiles/:id/edit-cref" },
            ]}
        >
            {status && status.sent && (
                <Alert style={{ marginBottom: 16 }} severity="success">
                    Alteração Realizada!
                </Alert>
            )}

            {isSubmitting ? (
                <Box display="flex" justifyContent="center" my={6}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <CrefEditForm data={data} onDataChange={setData} />
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Salvar Alterações
                    </Button>
                    <SpaceBar style={{ color: "transparent" }}></SpaceBar>
                    <Button variant="contained" color="default" component={NavLink} to={`/profiles`}>
                        Voltar
                    </Button>
                </>
            )}
        </ProtectedPage>
    );
};


export default EditCrefPage;