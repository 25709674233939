/* eslint-disable import/first */
import React from "react";

// Guards
import AuthGuard from "../components/AuthGuard";

// Auth components
import { SignInPage, SignOutPage } from "../pages/auth";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

// Protected routes
import {
  AdminDetailPage,
  AdminEditFormPage,
  AdminIndexPage,
  AdminRegisterFormPage,
  BannersDetailPage,
  BannersEditFormPage,
  BannersIndexPage,
  BannersRegisterFormPage,
  CategoriesDetailPage,
  CategoriesEditFormPage,
  CategoriesIndexPage,
  CategoriesRegisterFormPage,
  GroupsDetailPage,
  GroupsEditFormPage,
  GroupsIndexPage,
  GroupsRegisterFormPage,
  HomePage,
  LessonsDetailPage,
  LessonsIndexPage,
  PaymentsIndexPage,
  ProfilesIndexPage,
  ProfilesDetailPage,
  ProfilesEditFormPage,
  NotificationsIndexPage,
  ChangePasswordPage,
  EditCrefPage,
  WithdrawsIndexPage,
  WithdrawsEditFormPage,
  WithdrawsDetailPage,
  BuyerBalancesIndexPage,
} from "../pages/protected";
import {
  AccountCircle,
  AppsRounded,
  CreditCard,
  DirectionsRun,
  ExitToApp,
  LabelRounded,
  LocalOfferRounded,
  SupervisedUserCircle,
  NotificationsRounded,
  VpnKey,
  MonetizationOn,
  MoneyOff,
  AccountBalanceWallet,
} from "@material-ui/icons";
import { NotificationsFormPage } from "../pages/protected/NotificationsFormPage";
import { NotificationsDetailPage } from "../pages/protected/NotificationsDetailPage";
import { CampaignsIndexPage } from "../pages/protected/CampaignsIndexPage";
import { CampaignDetailPage } from "../pages/protected/CampaignDetailPage";
import { CampaignsFormPage } from "../pages/protected/CampaignsFormPage";

// Routes using the Auth layout
export const authRoutes = [
  {
    id: "auth-sign-in",
    path: "/auth/sign-in",
    component: SignInPage,
    children: null,
  },
  {
    id: "auth-sign-out",
    path: "/auth/sign-out",
    component: SignOutPage,
    children: null,
  },
  {
    id: "auth-404",
    path: "/auth/404",
    component: Page404,
    children: null,
  },
  {
    id: "auth-500",
    path: "/auth/500",
    component: Page500,
    children: null,
  },
];

// Routes that are protected

export const protectedRoutes = [
  {
    id: "home",
    path: "/",
    component: HomePage,
    guard: AuthGuard,
    children: null,
  },

  // beggining of admin section
  {
    id: "admin",
    path: "/admin-users",
    component: AdminIndexPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "admin-new",
    path: "/admin-users/new",
    component: AdminRegisterFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "admin-edit",
    path: "/admin-users/:id/edit",
    component: AdminEditFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "admin-detail",
    path: "/admin-users/:id/admin-detail",
    component: AdminDetailPage,
    guard: AuthGuard,
    children: null,
  },
  // end of admin section

  // beggining of groups section
  {
    id: "groups",
    path: "/groups",
    component: GroupsIndexPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "groups-new",
    path: "/groups/new",
    component: GroupsRegisterFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "groups-edit",
    path: "/groups/:id/edit",
    component: GroupsEditFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "groups-detail",
    path: "/groups/:id/groups-detail",
    component: GroupsDetailPage,
    guard: AuthGuard,
    children: null,
  },
  // end of groups section

  // beggining of categories section
  {
    id: "categories",
    path: "/categories",
    component: CategoriesIndexPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "categories-new",
    path: "/categories/new",
    component: CategoriesRegisterFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "categories-edit",
    path: "/categories/:id/edit",
    component: CategoriesEditFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "categories-detail",
    path: "/categories/:id/categories-detail",
    component: CategoriesDetailPage,
    guard: AuthGuard,
    children: null,
  },
  // end of categories section

  // beggining of banners section
  {
    id: "banners",
    path: "/banners",
    component: BannersIndexPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "banners-new",
    path: "/banners/new",
    component: BannersRegisterFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "banners-edit",
    path: "/banners/:id/edit",
    component: BannersEditFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "banners-detail",
    path: "/banners/:id/banners-detail",
    component: BannersDetailPage,
    guard: AuthGuard,
    children: null,
  },
  // end of banners section

  // beggining of profiles section
  {
    id: "profiles",
    path: "/profiles",
    component: ProfilesIndexPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "profiles-detail",
    path: "/profiles/:id/detail",
    component: ProfilesDetailPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "profiles-edit",
    path: "/profiles/:id/edit",
    component: ProfilesEditFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "profiles-edit-cref",
    path: "/profiles/:id/edit-cref",
    component: EditCrefPage,
    guard: AuthGuard,
    children: null,
  },
  // end of profiles section

  // beggining of payments section
  {
    id: "payments",
    path: "/payments",
    component: PaymentsIndexPage,
    guard: AuthGuard,
    children: null,
  },

  {
    id: "buyer-balances",
    path: "/buyer-balances",
    component: BuyerBalancesIndexPage,
    guard: AuthGuard,
    children: null,
  },
  // end of payments section

  // beggining of lessons section
  {
    id: "lessons",
    path: "/lessons",
    component: LessonsIndexPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "lessons-detail",
    path: "/lessons/:id/lessons-detail",
    component: LessonsDetailPage,
    guard: AuthGuard,
    children: null,
  },
  // end of lessons section

  {
    id: "change-password",
    path: "/change-password",
    component: ChangePasswordPage,
    guard: AuthGuard,
    children: null,
  },

  {
    id: "notifications",
    path: "/notifications",
    component: NotificationsIndexPage,
    guard: AuthGuard,
    children: null,
  },

  // beggining of withdraws section
  {
    id: "withdraws",
    path: "/withdraws",
    component: WithdrawsIndexPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "withdraws-detail",
    path: "/withdraws/:id/:invoiceLink/detail",
    component: WithdrawsDetailPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "withdraws-edit",
    path: "/withdraws/:id/edit",
    component: WithdrawsEditFormPage,
    guard: AuthGuard,
    children: null,
  },
  // end of withdraws section
  {
    id: "notifications-new",
    path: "/notifications/new",
    component: NotificationsFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "notifications-edit",
    path: "/notifications/:id/edit",
    component: NotificationsFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "notifications-detail",
    path: "/notifications/:id/notification-detail",
    component: NotificationsDetailPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "campaigns",
    path: "/campaigns",
    component: CampaignsIndexPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "campaigns-detail",
    path: "/campaigns/:id/campaign-detail",
    component: CampaignDetailPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "campaigns-new",
    path: "/campaigns/new",
    component: CampaignsFormPage,
    guard: AuthGuard,
    children: null,
  },
  {
    id: "campaigns-edit",
    path: "/campaigns/:id/edit",
    component: CampaignsFormPage,
    guard: AuthGuard,
    children: null,
  },
];

// Routes visible in the sidebar
export const sidebarRoutes = [
  {
    id: "Perfis",
    path: "/profiles",
    icon: <AccountCircle />,
    component: null,
    children: null,
    header: "Aplicativo",
  },

  {
    id: "Grupos",
    path: "/groups",
    icon: <AppsRounded />,
    component: null,
    children: null,
  },

  {
    id: "Categorias",
    path: "/categories",
    icon: <LabelRounded />,
    component: null,
    children: null,
  },

  {
    id: "Banners",
    path: "/banners",
    icon: <LocalOfferRounded />,
    component: null,
    children: null,
  },

  {
    id: "Aulas",
    path: "/lessons",
    icon: <DirectionsRun />,
    component: null,
    children: null,
  },

  {
    id: "Carteiras",
    path: "/buyer-balances",
    icon: <AccountBalanceWallet />,
    component: null,
    children: null,
  },

  {
    id: "Pagamentos",
    path: "/payments",
    icon: <CreditCard />,
    children: null,
    component: null,
  },

  {
    id: "Notificações",
    path: "/notifications",
    icon: <NotificationsRounded />,
    children: null,
    component: null,
  },

  {
    id: "Saques",
    path: "/withdraws",
    icon: <MonetizationOn />,
    children: null,
    component: null,
  },
  {
    id: "Campanhas",
    path: "/campaigns",
    icon: <MoneyOff />,
    children: null,
    component: null,
  },

  {
    id: "Administradores",
    path: "/admin-users",
    icon: <SupervisedUserCircle />,
    children: null,
    component: null,
    header: "Administrativo",
  },
  {
    id: "Alterar Senha",
    path: "/change-password",
    icon: <VpnKey />,
    children: null,
    component: null,
    header: "Meu Acesso",
  },
  {
    id: "Sair",
    path: "/auth/sign-out",
    icon: <ExitToApp />,
    children: null,
    component: null,
  },
];
