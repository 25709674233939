import { ProtectedPage } from "../../components";
import { spacing } from "@material-ui/system";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import {
    Button,
    Grid,
    IconButton,
    Paper as MuiPaper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@material-ui/core";
import { BaseList, BasePagination } from "../../types/api";
import { Campaign, list, deleteById } from "../../services/CampaignsService";
import { format } from "date-fns";
import { NavLink } from "react-router-dom";
import {
    Add,
    Delete as DeleteIcon,
    Edit as EditIcon,
    RemoveRedEye as RemoveRedEyeIcon,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { useAlert } from "../../hooks/useAlert";
import { DeleteConfirmationModal } from "../../components/Modal/DeleteConfirmationModal";

interface FormatedTableValues extends Omit<Campaign, 'splitPercent'> {
    splitPercent: string;
}

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable() {
    const [data, setData] = useState<BaseList<Campaign>>();
    const [itemToRemove, setItemToRemove] = useState<FormatedTableValues>()
    const [searchOptions, setSearchOptions] = useState<BasePagination>({
        page: 0,
        pageSize: 6,
    });

    const { page, pageSize } = searchOptions;

    const { alertOptions, showAlert } = useAlert();

    const handleChangePage = (
        _: any,
        newPage: number
    ) => {
        setSearchOptions({ ...searchOptions, page: newPage });
    };

    const listCampaigns = useCallback((): void => {
        list(searchOptions)
            .then((response) => setData(response.data))
            .catch((error) => {
                console.error(error);
                showAlert('Ocorreu um erro ao buscar as campanhas', 'error');
            })
    }, [searchOptions]);

    useEffect(() => {
        listCampaigns();
    }, [listCampaigns]);

    const tableKeys: Partial<keyof Campaign>[] = [
        'id',
        'title',
        'description',
        'startAt',
        'endAt',
        'splitPercent',
    ];

    const formatValues = (dataRow: Campaign): FormatedTableValues => {
        const descriptionMaxCharacters = 50;

        return {
            ...dataRow,
            startAt: format(new Date(dataRow.startAt), 'dd/MM/yyyy'),
            endAt: format(new Date(dataRow.endAt), 'dd/MM/yyyy'),
            splitPercent: `${dataRow.splitPercent}%`,
            description: dataRow.description?.length > descriptionMaxCharacters
                ? `${dataRow.description.substring(0, descriptionMaxCharacters)}...`
                : dataRow.description,
        };
    }

    const handleDeleteModalClose = (remove: boolean) => {
        if (remove && itemToRemove) {
            deleteById(itemToRemove.id)
                .then(() => {
                    showAlert('Removido com sucesso!', 'success');
                    listCampaigns();
                })
                .catch(() => showAlert('Houve um erro ao remover a campanha', 'error'));
        }

        setItemToRemove(undefined);
    }

    return (
        <div>
            {alertOptions?.show && (
                <Alert severity={alertOptions.type}>
                    {alertOptions?.message}
                </Alert>
            )}
            <DeleteConfirmationModal
                isOpen={!!itemToRemove}
                onClose={handleDeleteModalClose}
                title={itemToRemove?.title}
            />
            <Grid container={true} spacing={8}>
                <Grid item={true} xs={12}>
                    <Paper>
                        <TableContainer>
                            <Table
                                aria-labelledby="tableTitle"
                                size={"medium"}
                                aria-label="enhanced table"
                            >
                                <TableHead>
                                    <TableCell> ID </TableCell>
                                    <TableCell> Título </TableCell>
                                    <TableCell> Descrição resumida </TableCell>
                                    <TableCell> Data de Início  </TableCell>
                                    <TableCell> Data de fim </TableCell>
                                    <TableCell> Porcentagem </TableCell>
                                    <TableCell> Ações </TableCell>
                                </TableHead>

                                <TableBody>
                                    {data?.rows?.map(item => formatValues(item)).map((row) => (
                                        <TableRow
                                            hover={true}
                                            key={row.id}
                                            tabIndex={1}
                                        >
                                            {tableKeys.map((key) => (
                                                <TableCell key={key} component="th" scope="row">
                                                    <Typography>
                                                        {row[key]}
                                                    </Typography>
                                                </TableCell>
                                            ))}

                                            <TableCell align="right">
                                                <div style={{ display: 'flex' }}>
                                                    <IconButton
                                                        aria-label="details"
                                                        component={NavLink}
                                                        to={`/campaigns/${row.id}/campaign-detail`}
                                                        title="Visualizar Campanha"
                                                    >
                                                        <RemoveRedEyeIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        aria-label="edit"
                                                        component={NavLink}
                                                        to={`campaigns/${row.id}/edit`}
                                                        title="Editar Campanha"
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    {
                                                        !row.default &&
                                                        (<IconButton
                                                            aria-label="remove"
                                                            title="Excluir Campanha"
                                                            onClick={() => setItemToRemove(row)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>)
                                                    }
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TablePagination
                            rowsPerPageOptions={[pageSize || 6]}
                            component="div"
                            count={data?.count || 0}
                            rowsPerPage={pageSize || 6}
                            page={page}
                            onChangePage={handleChangePage}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}

export const CampaignsIndexPage = () => {
    return (
        <ProtectedPage
            title="Campanhas"
            breadcrumbs={[{
                name: 'Campanhas', to: '/campaigns'
            }]}
            action={
                <Button
                    color="primary"
                    component={NavLink}
                    exact={true}
                    startIcon={<Add />}
                    to="/campaigns/new"
                    variant="contained"
                >
                    Criar Campanha
                </Button>
            }
        >
            <Grid container={true} spacing={6}>
                <Grid item={true} xs={12}>
                    <EnhancedTable />
                </Grid>
            </Grid>
        </ProtectedPage>
    );
};
