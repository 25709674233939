import { spacing, SpacingProps } from "@material-ui/system";
import React, { useEffect, useState, VFC } from "react";
import styled from "styled-components";

import {
    Box,
    Button as MuiButton,
    Card as MuiCard,
    CardContent,
    CircularProgress,
    Grid,
    GridSize,
    Paper,
    Typography,
} from "@material-ui/core";
import { FFTextField } from "./FFTextField";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { useAlert } from "../hooks/useAlert";
import { useHistory, useParams } from "react-router-dom";
import { CampaignFormData, create, update, getById } from "../services/CampaignsService";
import { CustomAlert } from "./CustomAlert";

interface ButtonPropstype extends SpacingProps {
    component?: string;
}

const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)<ButtonPropstype>(spacing);

interface FormField {
    type: 'text' | 'number' | 'dateTime';
    name: keyof CampaignFormData;
    label: string;
    required?: boolean;
    multiline?: boolean;
    characterCounter?: boolean;
    max?: number;
    gridSize?: GridSize;
}

const CampaignForm: VFC = () => {
    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState<boolean>(false);
    const isEditing = !!id;
    const history = useHistory();

    const cleanFormData: Partial<CampaignFormData> = {
        title: '',
        splitPercent: 0,
        description: '',
        startAt: new Date(),
        endAt: new Date(),
    }

    const [formData, setFormData] = useState<Partial<CampaignFormData>>(cleanFormData);
    const [errors, setErrors] = useState<Partial<Record<keyof CampaignFormData, string>>>({});

    const { alertOptions, showAlert } = useAlert();

    useEffect(() => {
        if (id) {
            setLoading(true);
            getById(id)
                .then((response) => {
                    const { data } = response;
                    setFormData({
                        title: data.title,
                        description: data.description,
                        splitPercent: data.splitPercent,
                        startAt: new Date(data.startAt),
                        endAt: new Date(data.endAt),
                        link: data.link,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    showAlert('Ocorreu um erro ao buscar a campanha', 'error');
                })
                .finally(() => setLoading(false));
        }
    }, [id]);

    const cleanRequired = (inputName: keyof CampaignFormData): void => {
        errors[inputName] = '';
        setErrors({ ...errors });
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const { name, value } = event.target;
        cleanRequired(name as keyof CampaignFormData);
        setFormData({ ...formData, [name]: value });
    }

    const formFields: FormField[] = [
        {
            type: 'text',
            name: 'title',
            label: 'Título',
            required: true,
            gridSize: 9,
        },
        {
            type: 'number',
            name: 'splitPercent',
            label: 'Porcentagem',
            required: true,
            gridSize: 3,
        },
        {
            type: 'text',
            name: 'description',
            label: 'Descrição',
            required: true,
            multiline: true,
            characterCounter: true,
            max: 150,
        },
        {
            type: 'text',
            name: 'link',
            label: 'Link',
        },
        {
            type: 'dateTime',
            name: 'startAt',
            label: 'Data de Início',
            required: true,
            gridSize: 6,
        },
        {
            type: 'dateTime',
            name: 'endAt',
            label: 'Data de Finalização',
            required: true,
            gridSize: 6,
        },
    ];

    const checkRequiredInputs = (): boolean => {
        const requiredInputs = formFields
            .filter((field) => field.required)
            .map((field) => field.name);

        let hasError = false;

        requiredInputs.forEach((inputName) => {
            if (!formData[inputName]) {
                hasError = true;
                errors[inputName] = `Campo ${inputName} é obrigatório`;
                setErrors({ ...errors });
            }
        });

        return hasError;
    }

    const handleSubmit = async (): Promise<void> => {
        if (checkRequiredInputs()) {
            return;
        }

        setLoading(true);
        (isEditing ? update(id, formData as CampaignFormData) : create(formData as CampaignFormData))
            .then(() => {
                showAlert('Campanha salva com sucesso!', 'success');
                history.push('/campaigns');
            })
            .catch((error) => {
                console.error(error);
                showAlert(error?.response?.data?.error || 'Ocorreu um erro ao salvar a campanha', 'error')
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <Card mb={6}>
            <CardContent>
                <Typography variant="h4" gutterBottom>
                    {isEditing ? 'Editar' : 'Criar'} Campanha
                </Typography>

                <CustomAlert alertOptions={alertOptions} />

                {loading ? (
                    <Box display="flex" justifyContent="center" my={6}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Paper style={{ marginTop: '16px' }}>
                        <form>
                            <Grid container spacing={4}>
                                {formFields.map((item) => (
                                    <Grid key={item.name} item md={item.gridSize as GridSize || 12}>
                                        {item.type === 'dateTime' ? (
                                            <KeyboardDateTimePicker
                                                autoOk
                                                fullWidth
                                                variant="inline"
                                                inputVariant="outlined"
                                                label={item.label}
                                                format="dd/MM/yyyy HH:mm"
                                                ampm={false}
                                                value={formData[item.name]}
                                                onChange={(date: Date | null) => setFormData({ ...formData, [item.name]: date })}
                                                required={item.required}
                                            />
                                        ) : (
                                            <>
                                                <FFTextField
                                                    fullWidth
                                                    type={item.type}
                                                    label={item.label}
                                                    name={item.name}
                                                    onChange={handleInputChange}
                                                    placeholder={item.label}
                                                    value={formData[item.name]}
                                                    variant="outlined"
                                                    required={item.required}
                                                    multiline={item.multiline}
                                                    maxLength={item.max}
                                                    error={!!errors?.[item.name]}
                                                    helperText={errors?.[item.name]}
                                                />
                                                {item.characterCounter && (
                                                    <span> {`${String(formData[item.name])?.length || 0}${item.max && ' / ' + item.max}`} </span>
                                                )}
                                            </>
                                        )}
                                    </Grid>
                                ))}
                            </Grid>

                            <Button
                                onClick={handleSubmit}
                                variant="contained"
                                color="primary"
                                mt={4}
                            >
                                Salvar
                            </Button>
                        </form>
                    </Paper>
                )}
            </CardContent>
        </Card>
    );
};
export default CampaignForm;
